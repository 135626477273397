import { arrayMoveImmutable } from "array-move";
import { createContext, ReactNode, useCallback, useState } from "react";
import { CODE_REFERENTIEL } from "types";
import { DesserteResource, TrajetResource } from "types/transport.types";
import { CODE_ETAT_TRAJET } from "utils/constant";
import { uuid } from "utils/helpers";

type ITrajetContext = {
  moveTrajets: (direction: "up" | "down", trajets: TrajetResource[]) => void;
  computeTrajets: (dessertes?: DesserteResource[]) => void;
  setTrajets: (trajets: TrajetResource[]) => void;
  setChecked: (trajets: TrajetResource[]) => void;
  trajets: TrajetResource[];
  checked: TrajetResource[];
  handleToggle: (site: TrajetResource) => void;
  duplicateSite: (site: TrajetResource) => void;
  deleteSite: (site: TrajetResource) => void;
};

const TrajetContext = createContext<ITrajetContext>({} as ITrajetContext);

type TrajetContextProps = {
  children: ReactNode;
};

export const buildTrajetSite = (
  idSite: string,
  nomSite: string,
  etat = CODE_ETAT_TRAJET.non_passe
) => {
  return { nomSite, idSite, etat, id: uuid() };
};

export const removeTrajetSiteDoublon = (data: TrajetResource[]) => {
  return data.filter((value, index, self) => {
    return self.findIndex((item) => item.idSite === value.idSite) === index;
  });
};

export const buildTrajets = (
  dessertes?: DesserteResource[],
  etat = CODE_ETAT_TRAJET.non_passe
) => {
  if (!dessertes) {
    return [];
  }
  return dessertes
    .flatMap((desserte) => {
      const sites = [];
      // Les dessertes en tranist ont déjà les colis. Donc on ajoute pas leur site de départ
      if (desserte.etatDesserte?.id !== CODE_REFERENTIEL.TRANSIT) {
        sites.push(desserte.siteDepart);
      }
      return sites.concat(desserte.siteArrivee);
    })
    .filter((value, index, self) => {
      return self.findIndex((item) => item.id === value.id) === index;
    })
    .map(({ id, nom }) => buildTrajetSite(id, nom, etat));
};

function TrajetProvider({ children }: TrajetContextProps) {
  const [trajets, _setTrajets] = useState<TrajetResource[]>([]);
  const [checked, setChecked] = useState<TrajetResource[]>([]);

  const setTrajets = useCallback((data: TrajetResource[]) => {
    _setTrajets(data);
    setChecked([]);
  }, []);

  const computeTrajets = useCallback((dessertes?: DesserteResource[]) => {
    if (!dessertes) {
      return;
    }
    _setTrajets(buildTrajets(dessertes));
    setChecked([]);
  }, []);

  const moveTrajets = useCallback(
    (direction: "up" | "down", values: TrajetResource[]) => {
      if (values.length) {
        _setTrajets((prevRight) => {
          let rightCopy = [...prevRight];
          prevRight
            .filter((item) => {
              return values.map((s) => s.id).includes(item.id);
            })
            .forEach((element) => {
              const index = rightCopy.findIndex(
                (item) => item.id === element.id
              );
              if (index >= 0) {
                rightCopy = arrayMoveImmutable(
                  rightCopy,
                  index,
                  direction === "up" ? index - 1 : index + 1
                );
              }
            });
          return rightCopy;
        });
      }
    },
    []
  );

  const duplicateSite = useCallback(
    (site: TrajetResource) => {
      const index = trajets.findIndex((e) => e.id === site.id);
      _setTrajets([
        ...trajets.slice(0, index + 1),
        { ...site, id: uuid(), etat: "new_site" },
        ...trajets.slice(index + 1),
      ]);
    },
    [trajets]
  );

  const deleteSite = useCallback(
    (site: TrajetResource) => {
      _setTrajets(trajets.filter((e) => e.id !== site.id));
    },
    [trajets]
  );

  const handleToggle = useCallback((trajet: TrajetResource) => {
    setChecked((prevChecked) => {
      const currentIndex = prevChecked.findIndex(
        (item) => item.id === trajet.id
      );
      const newChecked = [...prevChecked];

      if (currentIndex === -1) {
        newChecked.push(trajet);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      return newChecked;
    });
  }, []);

  return (
    <TrajetContext.Provider
      value={{
        trajets,
        moveTrajets,
        computeTrajets,
        handleToggle,
        setTrajets,
        checked,
        duplicateSite,
        deleteSite,
        setChecked,
      }}
    >
      {children}
    </TrajetContext.Provider>
  );
}

export { TrajetProvider, TrajetContext };

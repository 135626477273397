import { Button, Chip, Grid } from "@mui/material";

import { FC } from "react";
import { ViewItem2 as ViewItem, ViewList, ViewListItem } from "pages/common";
import {
  DesserteResource,
  EquipageResource,
  JonctionResource,
} from "types/transport.types";
import { Collapse, PersonnelAvatar } from "components";
import { EquipageJonctionActions } from "pages/tournees/common";

export const formatEquipage = (equipage: EquipageResource) => {
  const { chefDeBord, chauffeur, agentGarde } = equipage;
  return `${chefDeBord.firstname} / ${chauffeur.firstname} / ${agentGarde.firstname}`;
};

type EquipageJonctionItemProps = {
  jonction: JonctionResource;
  tourneeDessertes: DesserteResource[];
  canEdit: boolean;
  updateJonction: (jonction: JonctionResource) => void;
  deleteJonction: (jonction: JonctionResource) => void;
  refreshDessertes: () => void;
};

export const EquipageJonctionItem: FC<EquipageJonctionItemProps> = ({
  canEdit,
  jonction,
  tourneeDessertes,
  updateJonction,
  deleteJonction,
  refreshDessertes,
}) => {
  if (!jonction.tourneeJonction?.equipages) {
    return null;
  }

  const equipage = jonction.tourneeJonction.equipages.find(
    (e) => e.initial === "1"
  );

  if (!equipage) {
    return null;
  }

  return (
    <Collapse
      title={`Jonction N°${jonction.id}`}
      subtitle={formatEquipage(equipage)}
      sx={{ marginBottom: 3 }}
      actions={
        canEdit && (
          <EquipageJonctionActions
            equipage={equipage}
            tourneeDessertes={tourneeDessertes}
            refreshDessertes={refreshDessertes}
            updateJonction={updateJonction}
            deleteJonction={deleteJonction}
            jonction={jonction}
          />
        )
      }
    >
      <ViewList>
        <ViewListItem>
          <Grid item xs={12} md={3}>
            <ViewItem label="Tournée N°">
              {jonction.tourneeJonction.numero}
            </ViewItem>
          </Grid>
          <Grid item xs={12} md={3}>
            <ViewItem label="Centre">{jonction.centre.libelle}</ViewItem>
          </Grid>
          <Grid item xs={12} md={3}>
            <ViewItem label="Lieu">{jonction.lieu}</ViewItem>
          </Grid>
        </ViewListItem>

        <ViewListItem>
          <Grid item xs={12} md={3}>
            <ViewItem>
              <PersonnelAvatar
                personnel={equipage.chefDeBord}
                withMatricule={false}
                subtitle="Chef de bord"
              />
            </ViewItem>
          </Grid>
          <Grid item xs={12} md={3}>
            <ViewItem>
              <PersonnelAvatar
                personnel={equipage.chauffeur}
                withMatricule={false}
                subtitle="Chauffeur"
              />
            </ViewItem>
          </Grid>
          <Grid item xs={12} md={3}>
            <ViewItem>
              <PersonnelAvatar
                personnel={equipage.agentGarde}
                withMatricule={false}
                subtitle="Agent garde"
              />
            </ViewItem>
          </Grid>

          <Grid item xs={12} md={3}>
            <ViewItem label="Dessertes">
              <Chip
                size="small"
                color="info"
                variant="light"
                sx={{ fontWeight: 600 }}
                label={jonction.dessertes.length}
              />
              <Button sx={{ marginLeft: 1 }} size="extraSmall">
                VOIR
              </Button>
            </ViewItem>
          </Grid>
        </ViewListItem>
      </ViewList>
    </Collapse>
  );
};

import { List, Typography } from "@mui/material";

import MainCard from "components/MainCard";

import { FC, ReactNode, useContext } from "react";
import SimpleBarScroll from "components/third-party/SimpleBar";
import { TrajetContext } from "pages/tournees/context";
import { PlanificationTrajetItem } from "./PlanificationTrajetItem";
import { ManualOrder } from "./ManualOrder";

type PlanificationTrajetPanelProps = {
  title: string;
  secondary?: ReactNode;
  withDuplicate?: boolean;
  withDelete?: boolean;
};

export const PlanificationTrajetPanel: FC<PlanificationTrajetPanelProps> = ({
  title,
  secondary,
  withDuplicate,
  withDelete,
}) => {
  const {
    trajets,
    handleToggle,
    checked,
    moveTrajets,
    duplicateSite,
    deleteSite,
  } = useContext(TrajetContext);

  return (
    <MainCard
      content={false}
      elevation={2}
      boxShadow
      title={<Typography>{title}</Typography>}
      sx={{ width: "100%" }}
      headerSX={{ backgroundColor: "#f7f7f7" }}
      secondary={
        <>
          <ManualOrder
            move={(direction) => {
              moveTrajets(direction as any, checked);
            }}
          />
          {secondary}
        </>
      }
    >
      <List
        role="list"
        sx={{
          width: "100%",
          height: "450px",
          bgcolor: "background.paper",
          overflow: "hidden",
          pt: 0,
        }}
      >
        <SimpleBarScroll>
          {trajets.map((trajet) => (
            <PlanificationTrajetItem
              isChecked={
                checked.findIndex((item) => item.id === trajet.id) !== -1
              }
              trajet={trajet}
              key={trajet.id}
              handleToggle={handleToggle}
              onDuplicate={duplicateSite}
              onDelete={deleteSite}
              withDuplicate={withDuplicate}
              withDelete={withDelete}
            />
          ))}
        </SimpleBarScroll>
      </List>
    </MainCard>
  );
};

import { numberHelper, selectHelper } from "utils/helpers";
import { dateFormater, dateParser } from "utils/date";
import { DesserteResource } from "types/transport.types";

const toColis = (data?: any) => {
  return data?.map(toColisItem);
};

const filterBilleterie = (data?: any[]) => {
  return data
    ?.filter((e: any) => Object.values(e).every(Boolean))
    ?.map((e: any) => ({ ...e, quantite: numberHelper.trim(e.quantite) }));
};

const toColisItem = (item: Record<string, any>) => {
  return {
    id: item.id,
    numero: item.numero,
    montant: numberHelper.trim(item.montant),
    sousColis: item.sousColis?.map((sousColi: any) => ({
      id: sousColi.id,
      numero: sousColi.numero,
      montant: numberHelper.trim(sousColi.montant),
    })),
    detailBillets: filterBilleterie(item?.detailBillets),
    detailPieces: filterBilleterie(item?.detailPieces),
  };
};

const toFullBody = (data: any) => {
  const { dateDepart, heureDepart, dateArrivee, heureArrivee, transport } =
    data;

  return {
    depart: {
      dateDepart: dateFormater.toBackPattern(dateDepart),
      heureDepart: dateFormater.toBackTime(heureDepart),
      kmDepart: numberHelper.trim(data.kmDepart),
    },

    arrivee: {
      dateArrivee: dateFormater.toBackPattern(dateArrivee),
      heureArrivee: dateFormater.toBackTime(heureArrivee),
      kmArrivee: numberHelper.trim(data.kmArrivee),
    },

    infoGenerale: {
      siteDepartId: selectHelper.getValue(data.siteDepart),
      siteArriveeId: selectHelper.getValue(data.siteArrivee),
      clientId: selectHelper.getValue(data.client),
      provenanceId: selectHelper.getValue(data.provenance),
      typeDesserteId: selectHelper.getValue(data.typeDesserte),
      centreId: selectHelper.getValue(data.centre),
      typeVehiculeId: selectHelper.getValue(data.typeVehicule),
      heureDebutPassage: dateFormater.toBackTime(data.heureDebutPassage),
      heureFinPassage: dateFormater.toBackTime(data.heureFinPassage),
      escale: data.escale,
    },

    facturation: {
      siteFactureId: selectHelper.getValue(data.siteFacture),
      intramuros: transport.intramuros,
      modeFacture: transport.modeFacture,

      montantForfaitaire: numberHelper.trim(transport.montantForfaitaire),
      montantJrFerie: numberHelper.trim(transport.montantJrFerie),
      montantMensuel: numberHelper.trim(transport.montantMensuel),
      montantKmVb: numberHelper.trim(transport.montantKmVb),
      montantKmVnb: numberHelper.trim(transport.montantKmVnb),
      kmVoieBitumee: numberHelper.trim(data.kmVoieBitumee),
      kmVoieNonBitumee: numberHelper.trim(data.kmVoieNonBitumee),
      pourcentageColis: numberHelper.trim(transport.pourcentageColis),
    },

    colis: toColis(data.colis),
  };
};

const toBody = (data: any) => {
  const {
    infoGenerale,
    colis,
    facturation,
    depart: { dateDepart },
  } = toFullBody(data);

  return { ...infoGenerale, ...facturation, dateDepart, colis };
};

const toInput = (desserte: DesserteResource) => {
  const {
    client,
    centre,
    typeDesserte,
    etatDesserte,
    provenance,
    siteDepart,
    dateDepart,
    siteArrivee,
    siteTransit,
    dateArrivee,
    colis,
    intramuros,
    modeFacture,
    siteFacture,
    typeVehicule,
    heureDebutPassage,
    heureFinPassage,
    escale,
    tournee,
  } = desserte;

  const siteDepartItem = selectHelper.build(siteDepart.nom, siteDepart.id);
  const siteArriveeItem = selectHelper.build(siteArrivee.nom, siteArrivee.id);

  const data = {
    id: desserte.id,
    numero: desserte.numero,
    note: desserte.note,
    client: selectHelper.build(client?.nom, client?.id),
    centre: selectHelper.buildCentre(centre),
    provenance: selectHelper.buildReferentiel(provenance),
    hasTournee: !!tournee?.id,
    typeDesserte: selectHelper.buildReferentiel(typeDesserte),
    etatDesserte: selectHelper.buildReferentiel(etatDesserte),
    siteDepart: siteDepartItem
      ? {
          ...siteDepartItem,
          heureDebutPassage: siteDepart.heureDebutPassage,
          heureFinPassage: siteDepart.heureFinPassage,
        }
      : null,
    dateDepart: dateParser.toDate(dateDepart),
    heureDepart: dateParser.createDateFromTime(desserte.heureDepart),
    kmDepart: desserte.kmDepart,
    siteArrivee: siteArriveeItem
      ? {
          ...siteArriveeItem,
          heureDebutPassage: siteArrivee.heureDebutPassage,
          heureFinPassage: siteArrivee.heureFinPassage,
        }
      : null,

    siteTransit: selectHelper.build(siteTransit?.nom, siteTransit?.id),
    dateArrivee: dateParser.toDate(dateArrivee),
    heureArrivee: dateParser.createDateFromTime(desserte.heureArrivee),
    kmArrivee: desserte.kmArrivee,
    colis: colis || [],
    escale,
    siteFacture: selectHelper.build(siteFacture?.nom, siteFacture?.id),
    typeVehicule: selectHelper.build(typeVehicule?.code, typeVehicule?.id),
    heureDebutPassage: dateParser.createDateFromTime(heureDebutPassage),
    heureFinPassage: dateParser.createDateFromTime(heureFinPassage),
    kmVoieBitumee: desserte.kmVoieBitumee,
    kmVoieNonBitumee: desserte.kmVoieNonBitumee,
    transport: {
      montantKmVb: desserte.montantKmVb,
      montantKmVnb: desserte.montantKmVnb,
      montantForfaitaire: desserte.montantForfaitaire,
      montantJrFerie: desserte.montantJrFerie,
      montantMensuel: desserte.montantMensuel,
      pourcentageColis: desserte.pourcentageColis,
      intramuros: intramuros?.toString(),
      modeFacture: modeFacture?.toString(),
    },
  };

  return data;
};

export const desserteConverter = {
  toBody,
  toInput,
  toFullBody,
  toColis,
  toColisItem,
};

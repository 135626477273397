import { FC } from "react";
import { ParametrageResource } from "types";
import MainCard from "components/MainCard";
import { Collapse } from "components";
import { ViewItem2 as ViewItem, ViewList, ViewListItem } from "pages/common";
import { Grid } from "@mui/material";

type SettingGloablDetailProps = {
  parametrage: ParametrageResource;
};

const SettingGloablDetail: FC<SettingGloablDetailProps> = ({ parametrage }) => {
  const { value } = parametrage;
  return (
    <>
      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse title="Application" subtitle="Nom et logo de l'application">
          <ViewList>
            <ViewListItem>
              <Grid item xs={12} md={3}>
                <ViewItem label="Nom de l'application">
                  {value.application.nom}
                </ViewItem>
              </Grid>

              {/* <Grid item xs={12} md={3}>
                <ViewItem label="Logo">RCI</ViewItem>
              </Grid> */}
            </ViewListItem>
          </ViewList>
        </Collapse>
      </MainCard>

      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Pays"
          subtitle="Paramétrer les informations liées au pays"
        >
          <ViewList>
            <ViewListItem>
              <Grid item xs={12} md={3}>
                <ViewItem label="Nom du pays">{value.pays.nom}</ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Code pays">{value.pays.code}</ViewItem>
              </Grid>

              {/*               <Grid item xs={12} md={3}>
                <ViewItem label="Drapeau"></ViewItem>
              </Grid> */}
              <Grid item xs={12} md={3}>
                <ViewItem label="Devise">{value.pays.devise}</ViewItem>
              </Grid>
            </ViewListItem>
          </ViewList>
        </Collapse>
      </MainCard>

      {/* <MainCard sx={{ marginBottom: 3 }}>
        <Collapse title="TVA" subtitle="Renseigner la valeur de la tva">
          <ViewList>
            <ViewListItem>
              <Grid item xs={12} md={3}>
                <ViewItem label="Valeur">10%</ViewItem>
              </Grid>
            </ViewListItem>
          </ViewList>
        </Collapse>
      </MainCard> */}
    </>
  );
};

export default SettingGloablDetail;

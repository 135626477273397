import { ArrowRightAlt } from "@mui/icons-material";
import { Box, Chip, Stack, Typography } from "@mui/material";

import { FC, ReactNode } from "react";
import { TrajetResource } from "types/transport.types";
import { uuid } from "utils/helpers";

type ViewTrajetProps = {
  trajets?: TrajetResource[];
  renderItem: (trajet: TrajetResource) => ReactNode;
};

const COLORS: Record<string, any> = {
  non_passe: "default",
  passe: "success",
  en_cours: "info",
  annule: "error",
  ignore: "warning",
};

type TrajetItemProps = {
  trajet: TrajetResource;
  handleClick?: (e: any) => void;
  onDelete?: (e: any) => void;
};

export const TrajetItem: FC<TrajetItemProps> = ({
  trajet,
  handleClick,
  onDelete,
}) => {
  return (
    <Chip
      size="small"
      label={trajet.nomSite}
      color={COLORS[trajet.etat]}
      onClick={handleClick}
      onDelete={onDelete}
    />
  );
};

export const ViewTrajet: FC<ViewTrajetProps> = ({ trajets, renderItem }) => {
  return (
    <Stack
      spacing={0}
      padding={0}
      flexDirection="row"
      flexWrap="wrap"
      sx={{ marginBottom: 2 }}
    >
      {trajets?.map((trajet, i) => (
        <Box marginBottom={1} display="flex" alignItems="center" key={uuid()}>
          {i !== 0 && (
            <>
              <Typography
                component="span"
                sx={{ "&.MuiTypography-root": { alignSelf: "center" } }}
                fontWeight={400}
              >
                <ArrowRightAlt
                  fontSize="small"
                  sx={{ width: 30, alignSelf: "center" }}
                />
              </Typography>
            </>
          )}
          {renderItem(trajet)}
        </Box>
      ))}
    </Stack>
  );
};

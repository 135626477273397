import { Grid } from "@mui/material";
import { TextInput, ThousandFormat } from "components";
import { withForm, WrapperProps } from "hoc";
import { FC } from "react";
import { UtilitaireModal } from "../UtilitaireModal";
import * as yup from "yup";

const schema = yup.object({
  valeur: yup.string().required(),
});

const Form: FC<WrapperProps> = (props) => {
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title="Gestion billeterie"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextInput
            label="Valeur *"
            name="valeur"
            InputProps={{
              inputComponent: ThousandFormat as any,
            }}
          />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const BilleterieModal = withForm(Form, schema);

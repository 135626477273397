import { Receipt } from "@mui/icons-material";
import { Button } from "@mui/material";
import { PageTitle } from "pages/common";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeLoader, showLoader } from "reducers";
import { parametrageService } from "services";
import { ParametrageResource } from "types";
import { CODE_PARAMETRAGE } from "utils/constant";
import SettingFactureDetail from "./SettingFactureDetail";
import { SettingFactureForm } from "./SettingFactureForm";

const SettingFacture = () => {
  const [parametrage, setParametrage] = useState<
    ParametrageResource | undefined
  >();
  const [showForm, setShowForm] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLoader());
    parametrageService
      .findAll({
        code: CODE_PARAMETRAGE.transport_facture,
      })
      .then(({ data }) => {
        setParametrage(data[0] || ({} as ParametrageResource));
      })
      .finally(() => {
        dispatch(closeLoader());
      });
  }, [dispatch]);

  const save = (data: Record<string, any>) => {
    if (parametrage?.id) {
      return parametrageService.update(parametrage.id, {
        value: data,
      });
    }
    return parametrageService.create({
      value: data,
      code: CODE_PARAMETRAGE.transport_facture,
    });
  };

  if (!parametrage) {
    return null;
  }

  if (showForm) {
    return (
      <SettingFactureForm
        onBack={setShowForm.bind(this, false)}
        defaultValues={{
          ...(parametrage.value || {}),
          imprimeur: {
            ...(parametrage.value?.imprimeur || {}),
            mode: parametrage.value?.imprimeur?.mode || "personne_connectee",
          },
        }}
        onSave={save}
        onFinished={(data) => {
          setParametrage(data);
          setShowForm(false);
        }}
      />
    );
  }

  return (
    <>
      <PageTitle
        title="Paramétrage facture TDF"
        subtitle="Paramétrer facture et annexe"
        TitleIcon={Receipt}
        right={
          <Button
            onClick={setShowForm.bind(this, true)}
            color="error"
            variant="contained"
            sx={{ marginRight: 1 }}
          >
            Modifier
          </Button>
        }
      />

      <SettingFactureDetail parametrage={parametrage} />
    </>
  );
};

export default SettingFacture;

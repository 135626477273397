import { Grid } from "@mui/material";

import MainCard from "components/MainCard";

import { FC } from "react";
import {
  MontantExtraMuros,
  MontantIntraMuros,
  ViewIcons,
  ViewItem2 as ViewItem,
  ViewList,
  ViewListItem,
} from "pages/common";
import { DesserteResource } from "types/transport.types";
import { Collapse } from "components";
import { desserteHelper } from "utils/helpers";

type DesserteFacturationProps = {
  desserte: DesserteResource;
};

const DesserteFacturation: FC<DesserteFacturationProps> = ({ desserte }) => {
  const isIntraMuros = desserteHelper.isIntraMuros(
    desserte.intramuros?.toString()
  );
  return (
    <MainCard sx={{ marginBottom: 3 }}>
      <Collapse
        title="Facturation"
        subtitle="Information sur la facturation de la desserte"
        leftIcon={ViewIcons.facture}
      >
        <ViewList>
          <ViewListItem>
            <Grid item xs={12} md={3}>
              <ViewItem label="Site à facturer">
                {desserte.siteFacture?.nom}
              </ViewItem>
            </Grid>

            <Grid item xs={12} md={3}>
              <ViewItem label="Type véhicule">
                {desserte.typeVehicule?.code}
              </ViewItem>
            </Grid>

            <Grid item xs={12} md={3}>
              <ViewItem label="Régime">
                {desserteHelper.getLibelleRegime(desserte.intramuros)}
              </ViewItem>
            </Grid>
            {/* {isIntraMuros && (
              <Grid item xs={12} md={3}>
                <ViewItem label="Mode facture">
                  {desserteHelper.getLibelleModeFacture(desserte.modeFacture)}
                </ViewItem>
              </Grid>
            )} */}
          </ViewListItem>

          <ViewListItem>
            {isIntraMuros ? (
              <MontantIntraMuros
                montantForfaitaire={desserte.montantForfaitaire}
                montantJrFerie={desserte.montantJrFerie}
                montantMensuel={desserte.montantMensuel}
                pourcentageColis={desserte.pourcentageColis}
                modeFacture={desserte.modeFacture}
              />
            ) : (
              <MontantExtraMuros
                kmVoieBitumee={desserte.kmVoieBitumee}
                kmVoieNonBitumee={desserte.kmVoieNonBitumee}
                montantKmVb={desserte.montantKmVb}
                montantKmVnb={desserte.montantKmVnb}
              />
            )}
          </ViewListItem>
        </ViewList>
      </Collapse>
    </MainCard>
  );
};

export default DesserteFacturation;

import {
  format,
  parse,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
} from "date-fns";

export const DATE_PATTERN = {
  yyyyMMdd: "yyyy-MM-dd",
};

const toDate = (pattern: string, date?: string) => {
  return date ? parse(date, pattern, new Date()) : undefined;
};

export const parseToDate = (date?: string) => {
  return toDate("dd/MM/yyyy", date);
};

export const parseToDateTime = (
  date?: string,
  pattern: string = "dd/MM/yyyy HH:mm"
) => {
  return toDate(pattern, date);
};

export const convertToSavePattern = (date?: Date, pattern?: string) => {
  try {
    return date ? format(date, pattern || "yyyy-MM-dd HH:mm:ss") : null;
  } catch (error) {
    return undefined;
  }
};

export const setTime = (date?: Date | null, time?: Date | null) => {
  if (date) {
    const dateWithHour = setHours(date, time ? getHours(time) : 0);
    return setMinutes(dateWithHour, time ? getMinutes(time) : 0);
  }
  return undefined;
};

export const dateParser = {
  toDate: parseToDate,
  toDateTime: parseToDateTime,
  toSave: convertToSavePattern,
  createDateFromTime: (time?: string) => {
    if (!time) {
      return null;
    }
    let date = new Date();
    const times = time.split(":");
    date = setHours(date, parseInt(times[0] || "0"));
    return setMinutes(date, parseInt(times[1] || "0"));
  },
  createDateAndSetTime: (date?: string, heure?: string) => {
    const _date = dateParser.toDate(date);
    const _heure = dateParser.createDateFromTime(heure);
    return setTime(_date, _heure);
  },
};

const getDate = (date?: string | null) => {
  if (date) {
    return date.split(" ")[0];
  }
  return "";
};

const getTime = (date?: string) => {
  if (date) {
    return date.split(" ")[1] || "";
  }
  return "";
};

const getTimeThenConverter = (date?: string) => {
  if (date) {
    const time = date.split(" ")[1] || "";
    return time === "00:00" ? "" : time;
  }
  return "";
};

export const dateFormater = {
  toBackPattern: convertToSavePattern,
  toBackTime: (time?: Date) => {
    return time ? format(time, "HH:mm:ss") : null;
  },
  getDate,
  getTime,
  getMonth: (date?: Date) => {
    return convertToSavePattern(date, "MM");
  },
  getYear: (date?: Date) => {
    return convertToSavePattern(date, "yyyy");
  },
  getTimeThenConverter,
  getFullDateTime: (dateTime?: Date | null, withMinuit = false) => {
    if (dateTime) {
      try {
        const date = format(dateTime, "dd/MM/yyyy 'à' HH:mm");
        if (!withMinuit) {
          return date.replaceAll(" à 00:00", "");
        }
        return date;
      } catch (error) {
        console.error(error, "^");
      }
    }
    return "";
  },
  formatTimeFromBack: (time?: string) => {
    const dateTime = dateParser.createDateFromTime(time);
    return dateTime ? format(dateTime, "HH'h'mm") : undefined;
  },
};

import * as yup from "yup";

export const equipageValidator = {
  centre: yup.object().required().nullable(),
  // lieu: yup.string().required(),
  vehicule: yup.object().required().nullable(),
  chefDeBord: yup.object().required().nullable(),
  agentGarde: yup.object().required().nullable(),
  chauffeur: yup.object().required().nullable(),
  prestataires: yup.array().of(
    yup.object().shape({
      fullname: yup.string().required(),
    })
  ),
};

export const tourneeSchema = yup.object({
  dateDepart: yup.date().required(),

  etatTournee: yup.object().when("id", {
    is: (id?: string) => !!id,
    then: yup.object().required(),
  }),

  centre: yup.object().required().nullable(),

  //dessertes: yup.array().min(1).required("Au moins une desserte"),

  equipePrincipale: yup
    .object({
      ...equipageValidator,
    })
    .required()
    .nullable(),

  equipeJonction: yup.object().when("hasJonction", {
    is: (v?: boolean) => !!v,
    then: yup
      .object({
        ...equipageValidator,
        //dessertes: yup.array().min(1).required("Au moins une desserte"),
      })
      .required()
      .nullable(),
  }),
});

import { Alert, AlertTitle, Grid } from "@mui/material";
import { DateInput } from "components";
import { withForm, WrapperProps } from "hoc";
import { useFormContext } from "react-hook-form";
import { UtilitaireModal } from "pages/utilitaires";
import { FC } from "react";

import * as yup from "yup";

const Form: FC<WrapperProps> = (props) => {
  const { watch } = useFormContext();
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={`Dupliquer tournée N° ${watch("numero")}`}
      maxWidth="sm"
    >
      <Alert
        severity="warning"
        color="warning"
        variant="border"
        sx={{ marginBottom: 3 }}
      >
        <AlertTitle sx={{ fontWeight: 600, textTransform: "uppercase" }}>
          Duplication de tournee
        </AlertTitle>
        Vous êtes sur le point de <strong>de dupliquer</strong> une tournée avec
        les dessertes et l'équipage
      </Alert>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DateInput
            label="Date départ*"
            name="dateDepart"
            dateWrapperProps={{
              minDate: new Date(),
            }}
          />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const DuplicateTourneeModal = withForm(
  Form,
  yup.object({
    dateDepart: yup.date().required().nullable(),
  })
);

import { IconButton, Tooltip } from "@mui/material";

import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

import { FC } from "react";

type ManualOrderProps = {
  move: (direction: string) => void;
};

export const ManualOrder: FC<ManualOrderProps> = ({ move }) => {
  return (
    <>
      <Tooltip title="Faire monter">
        <IconButton
          size="small"
          color="primary"
          sx={{
            padding: 0,
            height: "20px",
            width: "20px",
            minWidth: "40px",
            marginRight: 1,
          }}
          onClick={() => move("up")}
        >
          <ArrowUpward sx={{ fontSize: 15 }} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Faire descendre">
        <IconButton
          size="small"
          color="primary"
          sx={{
            padding: 0,
            height: "20px",
            width: "20px",
            minWidth: "40px",
            marginRight: 2,
          }}
          onClick={() => move("down")}
        >
          <ArrowDownward sx={{ fontSize: 15 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

import { Launch } from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Collapse } from "components";
import MainCard from "components/MainCard";
import { useUser } from "hooks";
import { buildUserMenu } from "menu-items";
import { useMemo } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { LINKS } from "utils/constant";
import { isClient } from "utils/user.helpers";

export const HomePage = () => {
  const { user } = useUser();
  const navigation = useNavigate();

  const menuItems = useMemo(() => {
    return buildUserMenu(user);
  }, [user]);

  if (isClient(user)) {
    return <Navigate to={LINKS.clients.list} />;
  }

  return (
    <>
      {menuItems
        .filter(
          (item) => !["group-utilitaires", "group-dashboard"].includes(item.id!)
        )
        .map((item) => {
          return item.children?.map((e) => {
            const Icon = e.icon!;
            return (
              <MainCard
                sx={{ marginBottom: 3, backgroundColor: "#fafafa" }}
                key={e.id}
              >
                <Collapse
                  title={<>{e.title}</>}
                  leftIcon={<Icon />}
                  subtitle="Liste des fonctionnalités disponible"
                >
                  <Grid container spacing={3}>
                    {(e.children || [e]).map((menu) => {
                      return (
                        <Grid item xs={3} key={menu.id}>
                          <Card variant="elevation">
                            <CardContent>
                              <Typography
                                variant="h5"
                                component="div"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Link to={menu.url!} style={{ color: "#000" }}>
                                  {menu.title}
                                </Link>
                                <IconButton
                                  sx={{ marginLeft: "auto" }}
                                  onClick={() => {
                                    navigation(menu.url!);
                                  }}
                                >
                                  <Launch />
                                </IconButton>
                              </Typography>

                              <Typography
                                variant="body2"
                                sx={{ marginTop: "10px" }}
                              >
                                {menu.caption}
                              </Typography>
                            </CardContent>
                            <Divider />
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Collapse>
              </MainCard>
            );
          });
        })}
    </>
  );
};

import {
  Autocomplete,
  Box,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { DesserteSens } from "pages/dessertes";
import { FC, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { SelectItem } from "types/form.type";
import { SelectAsyncProps } from "types/select-async.type";
import { DesserteResource } from "types/transport.types";
import { getJsonValue } from "utils/helpers";

export const SelectDessertes: FC<
  SelectAsyncProps & {
    defaultOptions: DesserteResource[];
    limitTags?: number;
    onSelected?: (values: SelectItem[]) => void;
  }
> = ({ defaultOptions, onSelected, limitTags = 1, ...props }) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [selected, setSelected] = useState<any[]>(watch(props.name) || []);
  // const selected = watch(props.name) || [];
  const options = useMemo(() => {
    if (selected.length && defaultOptions.length) {
      return defaultOptions
        .filter((e) => !selected.map((d) => d.value).includes(e.id.toString()))
        .map((item) => ({
          label: `${item.siteDepart.nom} / ${item.siteArrivee.nom}`,
          value: item.id,
          desserte: item,
        }));
    }
    return defaultOptions.map((item) => ({
      label: `${item.siteDepart.nom} / ${item.siteArrivee.nom}`,
      value: item.id,
      desserte: item,
    }));
  }, [defaultOptions, selected]);

  const error = getJsonValue(errors, props.name);
  return (
    <Autocomplete
      {...props}
      multiple
      options={options}
      disableCloseOnSelect
      value={selected}
      getOptionLabel={(option) => option.label}
      size="small"
      limitTags={limitTags}
      onChange={(event: any, newValue) => {
        setValue(props.name, newValue);
        setSelected(newValue);
        onSelected && onSelected(newValue);
      }}
      renderOption={(renderProps, option) => {
        const { desserte } = option;
        return (
          <Box
            component={ListItem}
            {...renderProps}
            //@ts-ignore
            alignItems="center"
            divider
            key={desserte.id}
            sx={{ "&:last-child": { border: 0 } }}
          >
            <ListItemText
              primary={<DesserteSens desserte={desserte} />}
              secondaryTypographyProps={{
                component: "div",
              }}
              secondary={
                <>
                  N° {desserte.numero}&nbsp;
                  {desserte.etatDesserte.id === "transit"
                    ? `(${desserte.etatDesserte.name})`
                    : ""}
                </>
              }
            />
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Une ou plusieurs dessertes"
          label={props.label || "Dessertes *"}
          error={!!error}
          helperText={error?.message?.toString() || ""}
        />
      )}
      sx={{
        "& .MuiAutocomplete-tag": {
          bgcolor: (t) => t.palette.grey[100],
          border: "1px solid",
          borderColor: (t) => t.palette.grey[400],
          "& .MuiSvgIcon-root": {
            color: "primary.main",
            "&:hover": {
              color: "primary.dark",
            },
          },
        },
        "& .MuiInputBase-sizeSmall": {
          input: {
            padding: "8px 8px 8px 8px !important",
          },
        },
      }}
      noOptionsText="Aucun résultat"
    />
  );
};

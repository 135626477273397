import { arrayMoveImmutable } from "array-move";
import { getHours, getMinutes } from "date-fns";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { DesserteResource } from "types/transport.types";
import { dateParser } from "utils/date";
import { TrajetContext } from "./TrajetContext";

type IPlanifierTourneeContext = {
  setTrieAutomatique: (trieAutomatique: boolean) => void;
  setDessertesAPlanifier: (dessertes: DesserteResource[]) => void;
  filtrer: (params: Record<string, any>) => void;
  handleToggle: (desserte: DesserteResource) => void;
  handleCheckedRight: (
    dessertes: DesserteResource[],
    isTrieAutomatique: boolean
  ) => void;
  handleCheckedLeft: (dessertes: DesserteResource[]) => void;
  move: (direction: "up" | "down", dessertes: DesserteResource[]) => void;
  left: DesserteResource[];
  right: DesserteResource[];
  checked: DesserteResource[];
  trieAutomatique: boolean;
  resetRight: () => void;
};

const PlanifierTourneeContext = createContext<IPlanifierTourneeContext>(
  {} as IPlanifierTourneeContext
);

type ConfigProviderProps = {
  children: ReactNode;
};

export function not(
  a: readonly DesserteResource[],
  b: readonly DesserteResource[]
) {
  return a.filter(
    (value) => b.findIndex((item) => item.id === value.id) === -1
  );
}

export function intersection(a: DesserteResource[], b: DesserteResource[]) {
  return a.filter(
    (value) => b.findIndex((item) => item.id === value.id) !== -1
  );
}

const computeTime = (d?: Date | null) => {
  return d ? getHours(d) + getMinutes(d) / 60 : undefined;
};

export const sortDesserte = (a: DesserteResource, b: DesserteResource) => {
  const dateFinA = dateParser.createDateFromTime(a.heureFinPassage);
  const dateFinB = dateParser.createDateFromTime(b.heureFinPassage);

  const dateDebutA = dateParser.createDateFromTime(a.heureDebutPassage);
  const dateDebutB = dateParser.createDateFromTime(b.heureDebutPassage);

  return (
    (computeTime(dateFinA) || 24) - (computeTime(dateFinB) || 24) ||
    (computeTime(dateDebutA) || 24) - (computeTime(dateDebutB) || 24)
  );
};

function PlanifierTourneeProvider({ children }: ConfigProviderProps) {
  const [alldessertes, setAlldessertes] = useState<DesserteResource[]>([]);
  const { computeTrajets } = useContext(TrajetContext);
  const [left, setLeft] = useState<DesserteResource[]>([]);
  const [right, setRight] = useState<DesserteResource[]>([]);

  const [checked, setChecked] = useState<DesserteResource[]>([]);

  const [trieAutomatique, setTrieAutomatique] = useState<boolean>(true);

  useEffect(() => {
    setRight((prevRight) => {
      if (trieAutomatique) {
        return [...prevRight].sort(sortDesserte);
      }
      return prevRight;
    });
  }, [trieAutomatique]);

  useEffect(() => {
    computeTrajets(right);
  }, [right, computeTrajets]);

  const setDessertesAPlanifier = useCallback((values: DesserteResource[]) => {
    setLeft(values.sort(sortDesserte));
    setAlldessertes(values);
    setRight([]);
  }, []);

  const resetRight = useCallback(() => {
    setRight([]);
  }, []);

  const handleToggle = useCallback((desserte: DesserteResource) => {
    setChecked((prevChecked) => {
      const currentIndex = prevChecked.findIndex(
        (item) => item.id === desserte.id
      );
      const newChecked = [...prevChecked];

      if (currentIndex === -1) {
        newChecked.push(desserte);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      return newChecked;
    });
  }, []);

  const handleCheckedRight = useCallback(
    (values: DesserteResource[], isTrieAutomatique: boolean) => {
      setRight((prevRight) => {
        const newRight = prevRight.concat(values);
        return isTrieAutomatique ? newRight.sort(sortDesserte) : newRight;
      });
      setLeft((prevLeft) => {
        return not(prevLeft, values);
      });
      setChecked((prevChecked) => {
        return not(prevChecked, values);
      });
    },
    []
  );

  const handleCheckedLeft = useCallback((values: DesserteResource[]) => {
    setLeft((prevLeft) => {
      return prevLeft.concat(values).sort(sortDesserte);
    });
    // setLeft((prev) => prev.concat(rightChecked).sort(sortDesserte));
    setRight((prevRight) => {
      return not(prevRight, values);
    });

    setChecked((prevChecked) => {
      return not(prevChecked, values);
    });
  }, []);

  const move = useCallback(
    (direction: "up" | "down", dessertes: DesserteResource[]) => {
      if (dessertes.length) {
        setRight((prevRight) => {
          let rightCopy = [...prevRight];
          dessertes.forEach((element) => {
            const index = rightCopy.findIndex((item) => item.id === element.id);
            if (index >= 0) {
              rightCopy = arrayMoveImmutable(
                rightCopy,
                index,
                direction === "up" ? index - 1 : index + 1
              );
            }
          });
          return rightCopy;
        });
      }
    },
    []
  );

  const filtrer = (params: Record<string, any>) => {
    const entries = Object.entries(params).filter(
      (entry) => entry[1] !== undefined && entry[1] !== null
    );

    const hasText = (values: (string | undefined)[]) => {
      return values.filter((e) => e !== undefined && e !== null);
    };

    setLeft(() => {
      if (!entries.length) {
        return not(alldessertes, right).sort(sortDesserte);
      }

      return not(alldessertes, right).filter((desserte) => {
        const { siteDepart, siteArrivee } = desserte;
        const filters: Record<string, (string | undefined)[]> = {
          typeVehiculeId: hasText([desserte.typeVehicule?.id]),
          villeId: hasText([siteDepart?.ville?.id, siteArrivee?.ville?.id]),
          situationGeographiqueId: hasText([
            siteDepart?.situationGeographique?.id,
            siteArrivee?.situationGeographique?.id,
          ]),
          etatId: hasText([
            desserte?.etatDesserte?.id,
            desserte?.etatDesserte?.id,
          ]),
          siteId: hasText([siteDepart?.id, siteArrivee?.id]),
          zoneId: hasText([siteDepart?.zone?.id, siteArrivee?.zone?.id]),
        };
        return entries.every((entry) => filters[entry[0]].includes(entry[1]));
      });
    });
  };

  return (
    <PlanifierTourneeContext.Provider
      value={{
        setTrieAutomatique,
        setDessertesAPlanifier,
        handleToggle,
        handleCheckedRight,
        handleCheckedLeft,
        move,
        filtrer,
        left,
        right,
        checked,
        trieAutomatique,
        resetRight,
      }}
    >
      {children}
    </PlanifierTourneeContext.Provider>
  );
}

export { PlanifierTourneeProvider, PlanifierTourneeContext };

import { tourneeService } from "services";
import { EquipageResource, TourneeResource } from "types/transport.types";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import { Collapse } from "components";
import { useFormContext } from "react-hook-form";
import { SelectDessertes } from "components/form/asycSelect/SelectDessertes";
import { withForm, WrapperProps } from "hoc";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { desserteService } from "services";
import { DesserteResource } from "types/transport.types";
import { dateFormater, dateParser } from "utils/date";

import * as yup from "yup";
import { TrajetContext } from "../context";
import { selectHelper } from "utils/helpers";
import { TrajetIcon } from "pages/common";
import { TrajetItem, ViewTrajet } from "../view/trajet";
import MainCard from "components/MainCard";
import { PlanificationTrajetPanel } from "../form/planification";
import { reBuildTrajets, useEditTrajet } from "./GererTrajet";

const Form: FC<WrapperProps> = ({ renderButtons, onSubmit }) => {
  const [dessertesAPlanifier, setDessertesAPlanifier] = useState<
    DesserteResource[]
  >([]);

  const { addSitesFromDessertes } = useEditTrajet();
  const { watch } = useFormContext();
  const { trajets } = useContext(TrajetContext);
  const tournee = watch("tournee");
  const equipage = watch("equipage");

  const { centreId, dateDepart } = useMemo(() => {
    return {
      centreId: tournee?.centre?.id,
      dateDepart: dateFormater.toBackPattern(
        dateParser.toDateTime(tournee.dateDepart, "dd/MM/yyyy"),
        "yyyy-MM-dd"
      ),
    };
  }, [tournee]);

  useEffect(() => {
    desserteService
      .aPlanifier({ dateDepart, centreId })
      .then(({ data }) => data)
      .then(setDessertesAPlanifier);
  }, [centreId, dateDepart]);

  return (
    <form onSubmit={onSubmit}>
      <Dialog maxWidth="lg" fullWidth open={true}>
        <DialogTitle>Ajouter des dessertes à la tournée</DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            backgroundColor: (t) => t.palette.grey[100],
            padding: "1.5rem !important",
          }}
        >
          <Grid container spacing={3}>
            <Grid xs={12} item md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SelectDessertes
                    name="dessertes"
                    label="Dessertes à ajouter *"
                    defaultOptions={dessertesAPlanifier}
                    limitTags={10}
                    disabled={!equipage}
                    onSelected={(selected) => {
                      const selectedIds = selected.map(selectHelper.getValue);
                      const dessertesSelected = dessertesAPlanifier.filter(
                        ({ id }) => selectedIds.includes(id)
                      );
                      addSitesFromDessertes(dessertesSelected);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MainCard>
                    <Collapse
                      title="Trajet"
                      subtitle="Itinéraire de l'équipe"
                      leftIcon={<TrajetIcon color="primary" />}
                    >
                      <ViewTrajet
                        trajets={trajets}
                        renderItem={(trajet) => <TrajetItem trajet={trajet} />}
                      />
                    </Collapse>
                  </MainCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item md={6}>
              <PlanificationTrajetPanel
                title="Organiser le trajet"
                withDuplicate
                withDelete
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{ marginTop: 0, marginLeft: "auto" }}
            display="flex"
            justifyContent="end"
          >
            {renderButtons()}
          </Box>
        </DialogActions>
      </Dialog>
    </form>
  );
};

const AddDesserteModal = withForm(
  Form,
  yup.object({
    dessertes: yup
      .array()
      .min(1, "Au moins une desserte")
      .required("Ce champs est obligatoire"),
    trajets: yup.array().min(1).required("Le trajet est obligatoire"),
    equipage: yup.object().required().nullable(),
  })
);

type AddDesserteProps = {
  tournee: TourneeResource;
  equipage: EquipageResource;
  onFinished: () => void;
  closeModal: () => void;
};

export const AddDesserte: FC<AddDesserteProps> = ({
  tournee,
  equipage,
  onFinished,
  closeModal,
}) => {
  const addDessertes = (data: Record<string, any>) => {
    return tourneeService.ajouterDessertes(tournee.id, {
      equipageId: equipage.id,
      dessertes: [
        ...(equipage.dessertes || []),
        ...(data.dessertes || [])
          .map(selectHelper.getValue)
          .map((id: any) => ({ id, etat: "actif" })),
      ],
      trajets: reBuildTrajets(data.trajets),
    });
  };

  return (
    <AddDesserteModal
      onBack={closeModal}
      onFinished={() => {
        onFinished();
        closeModal();
      }}
      onSave={addDessertes}
      defaultValues={{ tournee, equipage }}
    />
  );
};

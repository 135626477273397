import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";
import { MoreActions } from "components";
import { FC, useState } from "react";
import { JonctionResource, TourneeResource } from "types/transport.types";
import { AddJonction } from "./AddJonction";

type JonctionActionsProps = {
  tournee: TourneeResource;
  addJonction: (jonction: JonctionResource) => void;
};

export const JonctionActions: FC<JonctionActionsProps> = ({
  tournee,
  addJonction,
}) => {
  const [action, setAction] = useState<string | undefined>();

  const closeModal = () => {
    setAction(undefined);
  };

  return (
    <>
      <Box sx={{ marginRight: 1 }}>
        <MoreActions
          onSelected={({ code }) => setAction(code)}
          actions={[
            {
              code: "add",
              title: "Ajouter jonction",
              subtitle: "Ajouter une nouvelle jonction à la tournée",
              Icon: <Add color="primary" />,
            },
          ]}
          optionTitle="Actions"
        />
      </Box>

      {action === "add" && (
        <AddJonction
          tourneeDateDepart={tournee.dateDepart}
          tourneeDessertes={tournee.dessertes}
          tourneeId={tournee.id}
          closeModal={closeModal}
          onFinished={(data) => {
            closeModal();
            addJonction(data);
          }}
        />
      )}
    </>
  );
};

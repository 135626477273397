import { Grid } from "@mui/material";

import MainCard from "components/MainCard";

import { FC } from "react";
import {
  ViewIcons,
  ViewItem2 as ViewItem,
  ViewList,
  ViewListItem,
} from "pages/common";
import { DesserteResource } from "types/transport.types";
import { dateFormater } from "utils/date";
import { Collapse, Etat } from "components";
import { renderPlage } from "pages/tournees/form/planification";

type DesserteInformationGeneraleProps = {
  desserte: DesserteResource;
};

const DesserteInformationGenerale: FC<DesserteInformationGeneraleProps> = ({
  desserte,
}) => {
  return (
    <>
      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Information générale"
          subtitle="Informations générales de la desserte"
          leftIcon={ViewIcons.infoGenerale}
        >
          <ViewList>
            <ViewListItem>
              <Grid item xs={12} md={3}>
                <ViewItem label="N° desserte">{desserte.numero}</ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="N° tournée">
                  {desserte.tournee?.numero}
                </ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Centre">{desserte.centre?.libelle}</ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Client">{desserte.client?.nom}</ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Provenance">
                  {desserte.provenance?.name}
                </ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Type desserte">
                  {desserte.typeDesserte?.name}
                </ViewItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <ViewItem label="Heure passage">
                  {renderPlage(desserte)}
                </ViewItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <ViewItem label="Etat desserte">
                  {desserte.etatDesserte && (
                    <Etat
                      label={desserte.etatDesserte.name}
                      value={desserte.etatDesserte.id}
                    />
                  )}
                </ViewItem>
              </Grid>
            </ViewListItem>
          </ViewList>
        </Collapse>

        {/* <Grid item xs={12} md={3} marginTop={2}>
        <ViewItem label="En escale ?">
          {desserte.escale ? "Oui" : "Non"}
        </ViewItem>
      </Grid> */}
      </MainCard>

      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Départ et arrivée"
          subtitle="Information sur le départ et la livraison de la desserte"
          leftIcon={ViewIcons.depart}
        >
          <ViewList sx={{ marginBottom: 3 }}>
            <ViewListItem>
              <Grid item xs={12} md={3}>
                <ViewItem label="Site d'expédition">
                  {desserte.siteDepart?.nom}
                </ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Date d'expédition">
                  {dateFormater.getDate(desserte.dateDepart)}
                </ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Heure d'expédition">
                  {desserte.heureDepart}
                </ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Kilométrage départ">
                  {desserte.kmDepart}
                </ViewItem>
              </Grid>
            </ViewListItem>
          </ViewList>

          <ViewList>
            <ViewListItem>
              <Grid item xs={12} md={3}>
                <ViewItem label="Site destination">
                  {desserte.siteArrivee?.nom}
                </ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Date arrivée">
                  {dateFormater.getDate(desserte.dateArrivee)}
                </ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Heure arrivée">
                  {desserte.heureArrivee}
                </ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Kilométrage arrivée">
                  {desserte.kmArrivee}
                </ViewItem>
              </Grid>
            </ViewListItem>
          </ViewList>
        </Collapse>
      </MainCard>
    </>
  );
};

export default DesserteInformationGenerale;

import { FC } from "react";
import * as yup from "yup";
import { withForm, WrapperProps } from "hoc";
import MainCard from "components/MainCard";
import { Box, Button, Grid, TextField } from "@mui/material";
import { Collapse, TextInput } from "components";
import { PageTitle } from "pages/common";

const schema = yup.object({
  pays: yup.object().shape({
    nom: yup.string().required().nullable(),
    code: yup.string().required().nullable(),
    devise: yup.string().required().nullable(),
    //drapeau: yup.string().required().nullable(),
  }),

  application: yup.object().shape({
    nom: yup.string().required(),
    //logo: yup.string().required().nullable(),
  }),
});

const Form: FC<WrapperProps> = ({ renderButtons, onBack, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <PageTitle
        title="Modification"
        subtitle="Modifier le paramétrage global de l'application"
        onBack={onBack}
        right={renderButtons()}
      />
      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse title="Application" subtitle="Nom et logo de l'application">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                name="application.nom"
                label="Nom de l'application *"
              />
            </Grid>
            <Grid item xs={12} alignItems="center">
              <Button component="label" size="small" variant="outlined">
                Choisir un logo
                <TextField type="file" placeholder="Logo" />
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </MainCard>

      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Pays"
          subtitle="Paramétres des informations liées aux pays"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInput name="pays.nom" label="Nom du pays *" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput name="pays.code" label="Code du pays *" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput name="pays.devise" label="Dévise *" />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button component="label" size="small" variant="outlined">
                Choisir un drapeau
                <TextField type="file" placeholder="Logo" />
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </MainCard>

      <Box display="flex" justifyContent="flex-end">
        {renderButtons()}
      </Box>
    </form>
  );
};

export const SettingGlobalForm = withForm(Form, schema);

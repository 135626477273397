import { Chip, Grid } from "@mui/material";

import { FC, useMemo } from "react";
import {
  ViewIcons,
  ViewItem2 as ViewItem,
  ViewList,
  ViewListItem,
  ViewListItemEmpty,
} from "pages/common";
import {
  EquipageResource,
  TourneeResource,
  TrajetResource,
} from "types/transport.types";
import { Collapse, PersonnelAvatar } from "components";
import { RouteRounded } from "@mui/icons-material";
import { ListPrestataire } from "./ListPrestataire";
import MainCard from "components/MainCard";
import { TrajetItem, TrajetOptions, ViewTrajet } from "../trajet";
import { EquipageActions, TrajetActions } from "pages/tournees/common";

export const formatEquipage = (equipage: EquipageResource) => {
  const { chefDeBord, chauffeur, agentGarde } = equipage;
  return `${chefDeBord.firstname} / ${chauffeur.firstname} / ${agentGarde.firstname}`;
};

type ViewTourneeEquipageProps = {
  title: string;
  equipage: EquipageResource;
  canEdit: boolean;
  tournee: TourneeResource;
  updateEquipage: (equipage?: EquipageResource) => void;
  updateDessertes: () => void;
};

export const EquipageItem: FC<ViewTourneeEquipageProps> = ({
  equipage,
  canEdit,
  title,
  tournee,
  updateEquipage,
  updateDessertes,
}) => {
  const isJonction = equipage.motifChangement?.id === "jonction";
  const isActif = equipage.actif === "1";

  const hasDessertesActifs = useMemo(() => {
    return equipage.dessertes.every((desserte) => desserte.etat === "actif");
  }, [equipage]);

  const renderItem = (trajet: TrajetResource) => {
    if (canEdit) {
      return (
        <TrajetOptions
          dessertes={tournee.dessertes || []}
          trajet={trajet}
          equipage={equipage}
          onFinished={updateDessertes}
        />
      );
    }
    return <TrajetItem trajet={trajet} />;
  };

  return (
    <>
      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title={`${title} ${isJonction ? "(Jonction)" : ""}`}
          subtitle={formatEquipage(equipage)}
          leftIcon={ViewIcons.equipe}
          actions={
            canEdit && (
              <EquipageActions
                equipage={equipage}
                tournee={tournee}
                updateEquipage={updateEquipage}
                updateDessertes={updateDessertes}
              />
            )
          }
          defaultValue={isActif || isJonction}
        >
          <ViewList>
            <ViewListItem>
              <Grid item xs={12}>
                <Chip
                  size="small"
                  variant="light"
                  sx={{ fontWeight: 600 }}
                  color={equipage.actif === "1" ? "success" : "error"}
                  label={equipage.actif === "1" ? "Actif" : "Inactif"}
                />
                {hasDessertesActifs && isJonction && (
                  <Chip
                    size="small"
                    color="info"
                    variant="light"
                    sx={{ fontWeight: 600, marginLeft: 1 }}
                    label="A pris la main"
                  />
                )}
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Centre">{equipage.centre.libelle}</ViewItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <ViewItem label="Véhicule">
                  {equipage.vehicule.marque} (
                  {equipage.vehicule.typeVehicule?.code})
                </ViewItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <ViewItem label="Motif changement">
                  {equipage.motifChangement?.name && (
                    <Chip
                      size="small"
                      color="primary"
                      variant="outlined"
                      sx={{ fontWeight: 600 }}
                      label={equipage.motifChangement?.name}
                    />
                  )}
                </ViewItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <ViewItem label="Lieu">{equipage.lieu}</ViewItem>
              </Grid>
            </ViewListItem>

            <ViewListItem>
              <Grid item xs={12} md={3}>
                <ViewItem>
                  <PersonnelAvatar
                    personnel={equipage.chefDeBord}
                    withMatricule={false}
                    subtitle="Chef de bord"
                  />
                </ViewItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <ViewItem>
                  <PersonnelAvatar
                    personnel={equipage.chauffeur}
                    withMatricule={false}
                    subtitle="Chauffeur"
                  />
                </ViewItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <ViewItem>
                  <PersonnelAvatar
                    personnel={equipage.agentGarde}
                    withMatricule={false}
                    subtitle="Agent garde"
                  />
                </ViewItem>
              </Grid>

              <Grid item xs={12} md={3}>
                <ViewItem label="Dessertes">
                  <Chip
                    size="small"
                    color="info"
                    variant="light"
                    sx={{ fontWeight: 600 }}
                    label={equipage.dessertes.length}
                  />
                </ViewItem>
              </Grid>
            </ViewListItem>

            {!!equipage.prestataires?.length && (
              <Collapse
                title="Prestataires"
                subtitle="Liste des prestataires externes"
                leftIcon={ViewIcons.prestataire}
                sx={{ padding: 2 }}
                defaultValue={false}
              >
                <ListPrestataire prestataires={equipage.prestataires || []} />
              </Collapse>
            )}
          </ViewList>
          <Collapse
            title="Trajet"
            subtitle="Itinéraire de l'équipage"
            leftIcon={<RouteRounded color="primary" />}
            sx={{ marginTop: 3 }}
            actions={
              canEdit && (
                <TrajetActions
                  equipage={equipage}
                  updateEquipage={updateEquipage}
                />
              )
            }
          >
            {equipage.trajets.length ? (
              <ViewTrajet trajets={equipage.trajets} renderItem={renderItem} />
            ) : (
              <ViewListItemEmpty />
            )}
          </Collapse>
        </Collapse>
      </MainCard>
    </>
  );
};

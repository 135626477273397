import { AccessTime, LocalShippingOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Etat } from "components";
import { DesserteSens } from "pages/dessertes";

import { FC } from "react";
import { CODE_REFERENTIEL } from "types";
import { DesserteResource } from "types/transport.types";
import { dateFormater } from "utils/date";

type PlanificationDesserteItemProps = {
  desserte: DesserteResource;
  handleToggle: (desserte: DesserteResource) => void;
  isChecked: boolean;
};

export const renderPlage = (desserte: DesserteResource) => {
  const { heureDebutPassage, heureFinPassage } = desserte;

  const plage = [heureDebutPassage, heureFinPassage]
    .map((time) => dateFormater.formatTimeFromBack(time))
    .filter((time) => !!time)
    .join(" et ");

  if (!plage) {
    return "";
  }
  let qualificatif = "";
  if (heureFinPassage && heureDebutPassage) {
    qualificatif = "Entre";
  } else if (heureDebutPassage && !heureFinPassage) {
    qualificatif = "Après";
  } else if (heureFinPassage && !heureDebutPassage) {
    qualificatif = "Avant";
  }

  return `${qualificatif} ${plage}`;
};

export const PlanificationDesserteItem: FC<PlanificationDesserteItemProps> = ({
  desserte,
  isChecked,
  handleToggle,
}) => {
  return (
    <ListItem
      button
      alignItems="center"
      onClick={() => {
        handleToggle(desserte);
      }}
      divider
      sx={{
        py: 1.5,
        px: 1,
        "&.MuiListItem-root": {
          bgcolor: isChecked ? (t) => t.palette.grey[100] : "inherent",
          "&:hover": {
            bgcolor: (t) => t.palette.grey[50],
          },
        },
        "&:last-child": {
          border: 0,
        },
      }}
    >
      <ListItemIcon sx={{ marginRight: 0.75 }}>
        <Checkbox checked={isChecked} />
      </ListItemIcon>
      <ListItemText
        primary={<DesserteSens desserte={desserte} />}
        secondaryTypographyProps={{
          component: "div",
        }}
        secondary={
          <>
            <Stack
              spacing={0}
              padding={0}
              flexDirection="row"
              alignItems="center"
            >
              <LocalShippingOutlined
                color="primary"
                sx={{ fontSize: "1rem", marginRight: 1 }}
              />
              <Typography
                component="span"
                fontWeight={500}
                fontSize="0.8rem"
                marginRight={1}
              >
                {desserte.typeDesserte?.name}
                &nbsp;en&nbsp;{desserte.typeVehicule?.code}&nbsp;
              </Typography>
              <AccessTime
                sx={{ fontSize: "1rem", marginRight: 1 }}
                color="primary"
              />
              <Typography component="span" fontWeight={500} fontSize="0.8rem">
                {renderPlage(desserte)}
              </Typography>
            </Stack>
            {desserte.etatDesserte?.id === CODE_REFERENTIEL.TRANSIT && (
              <Box sx={{ marginTop: 1 }} component="div">
                <Etat
                  label={desserte.etatDesserte.name}
                  value={desserte.etatDesserte.id}
                />
              </Box>
            )}
          </>
        }
      />
    </ListItem>
  );
};

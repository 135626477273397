import { CheckCircle, FolderCopy } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { MoreActions } from "components";
import { DemarrerIcon, TerminerIcon } from "pages/common";
import { FC, useState } from "react";
import { CODE_REFERENTIEL } from "types";
import { TourneeResource } from "types/transport.types";
import { ApprouverTournee } from "./ApprouverTournee";
import { DemarrerTournee } from "./DemarrerTournee";
import { DuplicationTournee } from "./DuplicateTournee";
import { TerminerTournee } from "./TerminerTournee";

type TourneeActionsProps = {
  onFinish: (tournee?: TourneeResource) => void;
  tournee: TourneeResource;
  showExpand?: boolean;
  withLabel?: boolean;
  source: "list" | "view";
};

export const TourneeActions: FC<TourneeActionsProps> = ({
  tournee,
  onFinish,
  showExpand = false,
  source,
  withLabel,
}) => {
  const [action, setAction] = useState<string | undefined>();

  const canStart = tournee.etatTournee?.id === CODE_REFERENTIEL.INIT;
  // const isFinished = tournee.etatTournee?.id === CODE_REFERENTIEL.TERMINEE;

  const startButton = (
    <IconButton
      disabled={!canStart}
      color="info"
      onClick={() => setAction("demarrer")}
    >
      <DemarrerIcon />
    </IconButton>
  );

  return (
    <>
      {showExpand && (
        <>
          <Tooltip title="Dupliquer la tournée">
            <IconButton onClick={() => setAction("duplicate")} color="primary">
              <FolderCopy />
            </IconButton>
          </Tooltip>
          {canStart ? (
            <Tooltip title="Démarrer la tournée">{startButton}</Tooltip>
          ) : (
            startButton
          )}
        </>
      )}

      <MoreActions
        onSelected={({ code }) => setAction(code)}
        optionTitle={withLabel ? "Actions" : undefined}
        actions={[
          {
            code: "duplicate",
            subtitle: "Créer une tournée à partir d'une autre",
            title: "Dupliquer",
            Icon: <FolderCopy color="primary" />,
          },
          {
            code: "demarrer",
            title: "Démarrer",
            subtitle: "Commencer la tournée",
            Icon: <DemarrerIcon color="info" />,
            disabled: !canStart,
          },
          {
            code: "terminer",
            title: "Terminer",
            subtitle: "Terminer la tournée",
            Icon: <TerminerIcon />,
            disabled: tournee.etatTournee?.id !== CODE_REFERENTIEL.EN_COURS,
          },
          {
            code: "approuver",
            title: "Approuver",
            subtitle: "Confirmer la tournée",
            Icon: <CheckCircle color="success" />,
            disabled: !canStart,
          },
        ]}
      />

      {action === "duplicate" && (
        <DuplicationTournee
          tournee={tournee}
          onClose={() => setAction(undefined)}
        />
      )}

      {action === "demarrer" && (
        <DemarrerTournee
          onFinish={onFinish}
          tournee={tournee}
          onClose={() => setAction(undefined)}
        />
      )}

      {action === "terminer" && (
        <TerminerTournee
          onFinish={onFinish}
          tournee={tournee}
          onClose={() => setAction(undefined)}
        />
      )}

      {action === "approuver" && (
        <ApprouverTournee
          onFinish={onFinish}
          tournee={tournee}
          onClose={() => setAction(undefined)}
        />
      )}
    </>
  );
};

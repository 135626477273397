import { FC, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { LoadingButton } from "components";
import { TrajetResource } from "types/transport.types";
import { equipageService } from "services";
import { Dialog } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { useNotification } from "hooks";

type DeleteTrajetProps = {
  trajet: TrajetResource;
  equipageId: string;
  closeModal: () => void;
  onFinished: () => void;
};

export const DeleteTrajet: FC<DeleteTrajetProps> = ({
  trajet,
  equipageId,
  closeModal,
  onFinished,
}) => {
  const [isSaving, setSaving] = useState<boolean>(false);
  const notification = useNotification();

  const supprimer = () => {
    setSaving(true);
    equipageService
      .supprimerTrajet(equipageId, trajet.id)
      .then(() => {
        notification.success({
          title: "Le site a bien été supprimé",
        });
        onFinished();
      })
      .catch((e) => {
        notification.error({ title: e.message });
      })
      .finally(() => {
        setSaving(false);
        closeModal();
      });
  };

  return (
    <Dialog maxWidth="sm" open onClose={closeModal}>
      <DialogTitle>Confirmer Suppression</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vous voulez vraiment&nbsp;
          <Typography fontWeight={600} component="span" color="error">
            SUPPRIMER
          </Typography>
          &nbsp;le site&nbsp;
          {trajet.nomSite} ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Annuler</Button>
        <LoadingButton
          variant="contained"
          onClick={supprimer}
          autoFocus
          loading={isSaving}
          color="error"
        >
          Oui
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import ReportingIcon from "@mui/icons-material/AssessmentOutlined";
import { filterSousMenu } from "menu-items";
import { UserModule } from "types";
import { LINKS } from "utils/constant";

const sousmenus = [
  {
    id: "rapport_tdf",
    title: "Transport de fond",
    type: "item",
    url: LINKS.reporting.base,
    caption: "Consulter reporting TDF",
  },
  {
    id: "rapport_stock",
    title: "Gestion de stock",
    type: "item",
    url: LINKS.reporting.base,
    caption: "Consulter reporting stock",
  },
  {
    id: "rapport_ca",
    title: "Chiffre d'affaire",
    type: "item",
    url: LINKS.reporting.base,
    caption: "Consulter reporting chiffres d'affaires",
  },
];

export const buildReportingMenu = (userModule: UserModule) => {
  const data = filterSousMenu(userModule, sousmenus);

  if (data.length) {
    return {
      id: "group-reporting",
      type: "group",
      children: [
        {
          id: "reporting",
          title: "Reporting",
          type: "collapse",
          icon: ReportingIcon,
          children: data,
        },
      ],
    };
  }
  return null;
};

import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { withForm, WrapperProps } from "hoc";
import { UtilitaireModal } from "pages/utilitaires";
import { Grid } from "@mui/material";
import { SelectReferentielAsycForm, TextInput } from "components";
import { REFERENTIEL_TYPE } from "types";
import { incidentCommonSchema } from "pages/incidents/form";

const Form: FC<WrapperProps> = (props) => {
  const { watch } = useFormContext();
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={`Déclarer un incident sur la desserte N° ${watch("numero")}`}
      maxWidth="sm"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SelectReferentielAsycForm
            type={REFERENTIEL_TYPE.INCIDENT}
            name="typeIncident"
            label="Type d'incident *"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput name="lieu" label="Lieu *" />
        </Grid>
        <Grid item xs={12}>
          <TextInput name="libelle" label="Resumé *" />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            name="description"
            label="Description"
            placeholder="Renseigner plus de détail sur l'incident"
            rows={4}
            multiline
          />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const IncidentDesserteModal = withForm(Form, incidentCommonSchema);

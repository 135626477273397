import { FC } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { withForm, WrapperProps } from "hoc";
import { UtilitaireModal } from "pages/utilitaires";
import { Alert, Button, Grid } from "@mui/material";
import { TextInput, ThousandFormat, TimeInput } from "components";
import MainCard from "components/MainCard";
import { DesserteColisForm } from "pages/dessertes/form/DesserteColisForm";
import { DesserteResource, TrajetResource } from "types/transport.types";
import { numberHelper, updateElement, uuid } from "utils/helpers";
import * as yup from "yup";
import { dateFormater } from "utils/date";
import { equipageService } from "services";
import { desserteConverter } from "pages/dessertes/form";
import { CODE_REFERENTIEL } from "types";
import { TraitementHeader } from "./TraitementHeader";

const schema = yup.object({
  heure: yup.date().required().nullable(),
  signature: yup.object({
    signataire: yup.string().required().nullable(),
    objet: yup.string().required().nullable(),
  }),
  dessertes: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      colis: yup.array().of(
        yup.object().shape({
          numero: yup.string().required(),
          montant: yup.string().required(),

          sousColis: yup.array().of(
            yup.object().shape({
              numero: yup.string().required(),
              montant: yup.string().required(),
            })
          ),
        })
      ),
    })
  ),
});

const Form: FC<WrapperProps> = (props) => {
  const { watch, control } = useFormContext();

  const dessertesArray = useFieldArray({
    control,
    name: "dessertes",
  });

  const nomSite = watch("trajet")?.nomSite;

  const addColis = (desserteIndex: number) => () => {
    const desserte = watch(`dessertes.${desserteIndex}`);
    const colis = desserte?.colis || [];
    dessertesArray.update(desserteIndex, {
      ...desserte,
      colis: [
        ...colis,
        { numero: "", montant: "", sousColis: [], key: uuid() },
      ],
    });
  };

  const deleteColis = (desserteIndex: number) => (colisIndex: number) => {
    const desserte = watch(`dessertes.${desserteIndex}`);
    const colis = desserte?.colis || [];
    dessertesArray.update(desserteIndex, {
      ...desserte,
      colis: colis.filter((_: any, i: any) => colisIndex !== i),
    });
  };

  const updateColis =
    (desserteIndex: number) => (index: number, element: any) => {
      const desserte = watch(`dessertes.${desserteIndex}`);
      const colis = desserte?.colis || [];
      dessertesArray.update(desserteIndex, {
        ...desserte,
        colis: updateElement(colis, element, index),
      });
    };

  const hasDessertes = !!dessertesArray.fields.length;

  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title="Ramasser colis"
      maxWidth="md"
    >
      <Grid container spacing={2}>
        {hasDessertes ? (
          <>
            <Grid item xs={12}>
              <Alert severity="warning" variant="border">
                Prendre les colis sur&nbsp;
                <strong>{nomSite}</strong>
              </Alert>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextInput label="Objet *" name="signature.objet" />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextInput label="Signataire *" name="signature.signataire" />
            </Grid>

            <Grid item xs={12} md={2}>
              <TimeInput label="Heure *" name="heure" size="small" />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextInput
                label="Kilométrage"
                name="km"
                size="small"
                InputProps={{
                  inputComponent: ThousandFormat as any,
                }}
              />
            </Grid>
            {dessertesArray.fields.map((field: any, desserteIndex) => {
              const isSelected = watch(`dessertes.${desserteIndex}.selected`);
              return (
                <Grid item xs={12} key={field.id}>
                  <MainCard sx={{ "&:last-child": { border: 0 } }}>
                    <TraitementHeader desserteIndex={desserteIndex} />
                    {isSelected ? (
                      <>
                        {watch(`dessertes.${desserteIndex}.colis`)?.map(
                          (colis: any, colisIndex: number) => {
                            return (
                              <MainCard
                                key={colis.key}
                                sx={{
                                  marginBottom: 2,
                                  paddingY: 1.5,
                                  paddingX: 2,
                                  backgroundColor: "#fafafa",
                                }}
                                content={false}
                              >
                                <DesserteColisForm
                                  base={`dessertes.${desserteIndex}.`}
                                  colisIndex={colisIndex}
                                  deleteColis={deleteColis(desserteIndex)}
                                  updateColis={updateColis(desserteIndex)}
                                />
                              </MainCard>
                            );
                          }
                        )}
                        <Button
                          onClick={addColis(desserteIndex)}
                          size="small"
                          variant="contained"
                          color="info"
                          sx={{ marginLeft: "auto" }}
                          disabled={!isSelected}
                        >
                          Ajouter un colis
                        </Button>
                      </>
                    ) : (
                      <Alert severity="warning">
                        Cette desserte ne sera pas traité dans ce ramassage
                      </Alert>
                    )}
                  </MainCard>
                </Grid>
              );
            })}
          </>
        ) : (
          <Grid item xs={12}>
            <Alert severity="warning" variant="border">
              Aucun ramassage non traité n'a été trouvé sur&nbsp;
              <strong>{nomSite}</strong>
            </Alert>
          </Grid>
        )}
      </Grid>
    </UtilitaireModal>
  );
};

const RamasserColisModal = withForm(Form, schema);

type RamasserColisProps = {
  dessertes: DesserteResource[];
  trajet: TrajetResource;
  equipageId: string;
  closeModal: () => void;
  onFinished: () => void;
};

export const RamasserColis: FC<RamasserColisProps> = ({
  dessertes,
  trajet,
  equipageId,
  closeModal,
  onFinished,
}) => {
  const dessertesARamasser = dessertes
    .filter(
      (desserte) =>
        trajet.idSite === desserte.siteDepart.id &&
        desserte.etatDesserte?.id === CODE_REFERENTIEL.INIT
    )
    .map((desserte) => {
      return {
        id: desserte.id,
        numero: desserte.numero,
        depart: desserte.siteDepart.nom,
        arrivee: desserte.siteArrivee.nom,
        colis: [],
        selected: true,
      };
    });

  const prendreEnCharge = (data: Record<string, any>) => {
    return equipageService.prendreEnCharge(equipageId, trajet.id, {
      dessertes: data.dessertes
        .filter((desserte: any) => !!desserte.selected)
        .map((desserte: any) => ({
          id: desserte.id,
          colis: desserteConverter.toColis(desserte.colis),
        })),
      heureDepart: dateFormater.toBackTime(data.heure),
      kmDepart: numberHelper.trim(data.km),
      signature: data.signature,
    });
  };

  return (
    <RamasserColisModal
      onBack={closeModal}
      onSave={prendreEnCharge}
      onFinished={onFinished}
      defaultValues={{
        dessertes: dessertesARamasser,
        heure: new Date(),
        trajet,
        signature: {
          objet: `Ramassage du ${dateFormater.toBackPattern(
            new Date(),
            "dd/MM/yyyy HH:mm"
          )}`,
        },
      }}
    />
  );
};

import { ColisResource } from "types/transport.types";
import { useFormContext, useFieldArray } from "react-hook-form";
import { withForm, WrapperProps } from "hoc";
import { FC, useEffect, useState } from "react";

import * as yup from "yup";
import { UtilitaireModal } from "pages/utilitaires";
import { Alert, Grid, Stack } from "@mui/material";
import {
  Collapse,
  MontantFormatText,
  TextInput,
  ThousandFormat,
} from "components";
import { ListAlt } from "@mui/icons-material";
import { ViewListItemEmpty } from "pages/common";
import { colisService, parametrageService } from "services";

import { desserteConverter } from "../form/desserteConverter";

type BilleterieFormProps = {
  data: any[];
  base: string;
  label: string;
};

const BilleterieForm: FC<BilleterieFormProps> = ({ base, label, data }) => {
  return (
    <Grid container spacing={3}>
      {data.map.length ? (
        data.map((field: any, i: number) => {
          return (
            <Grid key={field.id} sm={6} item>
              <TextInput
                label={
                  <>
                    {label}&nbsp;
                    <MontantFormatText value={field.valeur} />
                  </>
                }
                name={`${base}.${i}.quantite`}
                InputProps={{
                  inputComponent: ThousandFormat as any,
                }}
              />
              <div style={{ display: "none" }}>
                <TextInput name={`${base}.${i}.valeur`} value={field.valeur} />
                <TextInput name={`${base}.${i}.id`} value={field.id} />
              </div>
            </Grid>
          );
        })
      ) : (
        <ViewListItemEmpty />
      )}
    </Grid>
  );
};
const Form: FC<WrapperProps> = (props) => {
  const { control, watch } = useFormContext();
  const [billets, setBillets] = useState<any[]>([]);
  const [pieces, setPieces] = useState<any[]>([]);
  const sousColisArray = useFieldArray({
    control,
    name: "sousColis",
  });
  useFieldArray({
    control,
    name: "detailBillets",
  });

  useFieldArray({
    control,
    name: "detailPieces",
  });

  const oldNumeroColis = watch("oldNumeroColis");

  useEffect(() => {
    Promise.all([
      parametrageService.findAll({ code: "billets" }),
      parametrageService.findAll({ code: "pieces" }),
    ]).then(([b, p]) => {
      setBillets(b.data[0].value as any[]);
      setPieces(p.data[0].value as any[]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={`Modifier colis ${oldNumeroColis}`}
    >
      <Alert color="error" variant="border" sx={{ marginBottom: 3 }}>
        Modification du colis&nbsp;
        <strong>N° {oldNumeroColis}</strong>&nbsp; déjà pris en charge.
      </Alert>

      <Grid container spacing={1.5}>
        <Grid item xs={12} md={6}>
          <TextInput label="Numéro colis *" name="numero" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Montant *"
            name="montant"
            InputProps={{
              inputComponent: ThousandFormat as any,
            }}
          />
        </Grid>
      </Grid>

      <Collapse
        title="Sous colis"
        subtitle="Liste des sous colis associée au colis"
        sx={{ marginTop: 2 }}
        leftIcon={<ListAlt color="primary" />}
      >
        {sousColisArray.fields.length ? (
          sousColisArray.fields.map((field: any, i: number) => {
            return (
              <Stack
                key={field.key}
                flexDirection="row"
                sx={{ marginBottom: 2 }}
              >
                <TextInput
                  label="Numéro sous colis *"
                  name={`sousColis.${i}.numero`}
                  sx={{ marginRight: 1 }}
                />
                <TextInput
                  label="Montant sous colis *"
                  name={`sousColis.${i}.montant`}
                  sx={{ marginRight: 1 }}
                  InputProps={{
                    inputComponent: ThousandFormat as any,
                  }}
                />
              </Stack>
            );
          })
        ) : (
          <ViewListItemEmpty />
        )}
      </Collapse>

      <Collapse
        title="Billets"
        subtitle="Ajouter le détail des billets"
        sx={{ marginTop: 2 }}
        leftIcon={<ListAlt color="primary" />}
      >
        <BilleterieForm
          data={billets}
          label="Nombre de billets"
          base="detailBillets"
        />
      </Collapse>

      <Collapse
        title="Pièces"
        subtitle="Ajouter le détail des pièces"
        sx={{ marginTop: 2 }}
        leftIcon={<ListAlt color="primary" />}
      >
        <BilleterieForm
          data={pieces}
          label="Nombre de pièces"
          base="detailPieces"
        />
      </Collapse>
    </UtilitaireModal>
  );
};

const ModifierColisModal = withForm(
  Form,
  yup.object({
    numero: yup.string().required(),
    montant: yup.string().required(),

    sousColis: yup.array().of(
      yup.object().shape({
        numero: yup.string().required(),
        montant: yup.string().required(),
      })
    ),
  })
);

type ModifierProps = {
  colis: ColisResource;
  onFinished: (colis: ColisResource) => void;
  closeModal: () => void;
};

export const ModifierColis: FC<ModifierProps> = ({
  colis,
  onFinished,
  closeModal,
}) => {
  const modifierColis = (data: Record<string, any>) => {
    return colisService.update(colis.id, desserteConverter.toColisItem(data));
  };

  return (
    <ModifierColisModal
      onBack={closeModal}
      onFinished={(data) => {
        onFinished(data);
        closeModal();
      }}
      onSave={modifierColis}
      defaultValues={{
        ...colis,
        oldNumeroColis: colis.numero,
      }}
    />
  );
};

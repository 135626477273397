import { Alert, AlertTitle, Grid } from "@mui/material";
import { Collapse, DateInput, TextInput } from "components";
import { withForm, WrapperProps } from "hoc";
import { useFormContext } from "react-hook-form";
import { UtilitaireModal } from "pages/utilitaires";
import { FC } from "react";

import * as yup from "yup";
import { startOfTomorrow } from "date-fns";
import { ListDesserteColis } from "../view/ListDesserteColis";
import { ViewIcons } from "pages/common";

const Form: FC<WrapperProps> = (props) => {
  const { watch } = useFormContext();
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title="Desserte en transit"
      maxWidth="md"
    >
      <Alert severity="warning" variant="border" sx={{ marginBottom: 3 }}>
        <AlertTitle sx={{ fontWeight: 600, textTransform: "uppercase" }}>
          Desserte en tranist
        </AlertTitle>
        Vous êtes sur le point de mettre la desserte&nbsp;
        <strong>N° {watch("numero")} en transit </strong>
      </Alert>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextInput label="Objet *" name="signature.objet" />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextInput label="Signataire *" name="signature.signataire" />
        </Grid>

        <Grid item xs={12} md={4}>
          <DateInput
            label="Nouvelle date départ*"
            name="dateDepart"
            dateWrapperProps={{
              minDate: startOfTomorrow(),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Collapse
            title="Colis"
            subtitle="Liste des colis qui seront en transit"
            leftIcon={ViewIcons.colis}
          >
            <ListDesserteColis colis={watch("colis")} />
          </Collapse>
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const TransitDesserteModal = withForm(
  Form,
  yup.object({
    dateDepart: yup.date().required().nullable(),
    signature: yup.object({
      signataire: yup.string().required().nullable(),
      objet: yup.string().required().nullable(),
    }),
  })
);

import { Alert, Badge, Box, Button, Stack, Tooltip } from "@mui/material";

import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  RouteRounded,
} from "@mui/icons-material";
import { useFormContext } from "react-hook-form";

import { useContext, useEffect, useState } from "react";
import {
  buildTrajetSite,
  intersection,
  PlanifierTourneeContext,
  TrajetContext,
} from "../../context";
import { TrierDessertes } from "./TrierDessertes";
import { ManualOrder } from "./ManualOrder";
import { PlanificationDessertePanel } from "./PlanificationDessertePanel";
import { PlanificationTrajetPanel } from "./PlanificationTrajetPanel";
import { AddSite } from "./AddSite";
import { useModal } from "hooks";
import { SelectItem } from "types/form.type";
import { CODE_ETAT_TRAJET } from "utils/constant";

export const PlanificationDessertes = () => {
  const {
    handleCheckedLeft,
    handleCheckedRight,
    handleToggle,
    move,
    left,
    right,
    checked,
    trieAutomatique,
  } = useContext(PlanifierTourneeContext);

  const {
    formState: { errors },
  } = useFormContext();

  const { trajets, setTrajets } = useContext(TrajetContext);

  const [mode, setMode] = useState<"dessertes" | "trajet">("dessertes");
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right).sort((a, b) => {
    return (
      right.findIndex((item) => item.id === a.id) -
      right.findIndex((item) => item.id === b.id)
    );
  });

  const siteModal = useModal();

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("dessertes", right);
  }, [right, setValue]);

  useEffect(() => {
    setValue("trajets", trajets);
  }, [trajets, setValue]);

  const addSites = (sites: SelectItem[]) => {
    setTrajets([
      ...trajets,
      ...sites.map((site) =>
        buildTrajetSite(site.value, site.label, CODE_ETAT_TRAJET.new_site)
      ),
    ]);
    siteModal.close();
  };

  return (
    <>
      {errors.dessertes && (
        <Alert color="error" variant="filled" sx={{ marginBottom: 2 }}>
          Il faut ajouter au moins une desserte dans la tournée
        </Alert>
      )}
      <Stack flexDirection="row" alignItems="center">
        <PlanificationDessertePanel
          checked={checked}
          dessertes={left}
          handleToggle={handleToggle}
          title="Liste des dessertes"
        />

        <Box sx={{ marginX: 1 }}>
          <Button
            variant={leftChecked.length ? "contained" : "outlined"}
            onClick={() => {
              handleCheckedRight(leftChecked, trieAutomatique);
            }}
            disabled={leftChecked.length === 0}
          >
            <Badge badgeContent={leftChecked.length} color="success">
              <KeyboardDoubleArrowRight />
            </Badge>
          </Button>
          <Button
            sx={{ mt: 2 }}
            variant={rightChecked.length ? "contained" : "outlined"}
            onClick={() => {
              handleCheckedLeft(rightChecked);
            }}
            disabled={rightChecked.length === 0}
          >
            <Badge badgeContent={rightChecked.length} color="error">
              <KeyboardDoubleArrowLeft />
            </Badge>
          </Button>
        </Box>
        {mode === "dessertes" ? (
          <PlanificationDessertePanel
            checked={checked}
            dessertes={right}
            handleToggle={handleToggle}
            title="Dessertes choisies"
            secondary={
              <>
                <TrierDessertes
                  trieAutomatique={trieAutomatique}
                  order={
                    <ManualOrder
                      move={(direction) => {
                        move(direction as any, rightChecked);
                      }}
                    />
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<RouteRounded />}
                  size="small"
                  onClick={() => {
                    setMode("trajet");
                  }}
                >
                  Gérer trajet
                </Button>
              </>
            }
          />
        ) : (
          <PlanificationTrajetPanel
            title="Trajet"
            secondary={
              <>
                <Tooltip title="Ajouter de nouveaux sites au trajet">
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    sx={{ marginRight: 1 }}
                    onClick={siteModal.show}
                    disabled={!right.length}
                  >
                    Ajouter site
                  </Button>
                </Tooltip>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setMode("dessertes");
                  }}
                >
                  Voir dessertes
                </Button>
              </>
            }
            withDuplicate
            withDelete
          />
        )}
      </Stack>
      {siteModal.open && (
        <AddSite closeModal={siteModal.close} saveSites={addSites} />
      )}
    </>
  );
};

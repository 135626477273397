import {
  Cancel,
  EditLocationSharp,
  TransferWithinAStation,
} from "@mui/icons-material";
import { MoreActions } from "components";
import { IncidentIcon } from "pages/common";
import { FC, useState } from "react";
import { CODE_REFERENTIEL } from "types";
import { DesserteResource } from "types/transport.types";
import { AnnulerDesserte } from "./AnnulerDesserte";
import { TransitDesserte } from "./TransitDesserte";
import { useUser } from "hooks";
import { IncidentDesserte } from "./IncidentDesserte";
import { useNavigate } from "react-router-dom";
import { LINKS } from "utils/constant";
import { ChangerDestinationDesserte } from "./ChangerDestinationDesserte";

type DesserteActionsProps = {
  onFinish: (desserte?: DesserteResource) => void;
  desserte: DesserteResource;
  tourneeId?: string;
  showExpand?: boolean;
  withLabel?: boolean;
  source: "list" | "view" | "tournee";
};

export const DesserteActions: FC<DesserteActionsProps> = ({
  desserte,
  onFinish,
  withLabel,
  source,
  tourneeId,
}) => {
  const [action, setAction] = useState<string | undefined>();
  const navigate = useNavigate();
  const { canWrite } = useUser();
  const canWriteDesserte = canWrite("dessertes");
  const isTransitable = [
    CODE_REFERENTIEL.EN_COURS,
    CODE_REFERENTIEL.TRANSIT,
    CODE_REFERENTIEL.A_REPLANIFIER,
  ].includes(desserte.etatDesserte?.id as any);
  const estLivree =
    CODE_REFERENTIEL.LIVRE === (desserte.etatDesserte?.id as any);

  const closeModal = () => setAction(undefined);

  if (!canWriteDesserte) {
    return null;
  }

  return (
    <>
      <MoreActions
        onSelected={({ code }) => setAction(code)}
        optionTitle={withLabel ? "Actions" : undefined}
        actions={[
          {
            code: "transit",
            subtitle: "Mettre la desserte en transit",
            title: "Transit",
            Icon: <TransferWithinAStation color="primary" />,
            disabled: !isTransitable || estLivree,
            show: canWriteDesserte,
          },
          {
            code: "destination",
            title: "Destination",
            subtitle: "Changer la destination de la desserte",
            Icon: <EditLocationSharp color="primary" />,
            disabled: estLivree,
            show: source === "tournee",
          },
          {
            code: "incident",
            title: "Incident",
            subtitle: "Déclarer un incident sur la desserte",
            Icon: <IncidentIcon color="warning" />,
            show: canWriteDesserte,
            disabled: ![CODE_REFERENTIEL.EN_COURS].includes(
              desserte?.etatDesserte?.id as any
            ),
          },
          {
            code: "annuler",
            title: "Annuler",
            subtitle: "Annuler le traitement de la desserte",
            Icon: <Cancel color="error" />,
            disabled:
              !!desserte.etatDesserte?.id &&
              [CODE_REFERENTIEL.LIVRE, CODE_REFERENTIEL.ANNULE].includes(
                desserte.etatDesserte.id as any
              ),
            show: canWriteDesserte,
          },
        ]}
      />

      {action === "transit" && (
        <TransitDesserte
          onFinish={onFinish}
          desserte={desserte}
          onClose={closeModal}
        />
      )}
      {action === "annuler" && (
        <AnnulerDesserte
          onFinish={onFinish}
          desserte={desserte}
          closeModal={closeModal}
        />
      )}
      {action === "incident" && (
        <IncidentDesserte
          onFinish={({ id }) => {
            navigate(LINKS.incidents.view(id));
          }}
          desserte={desserte}
          onClose={closeModal}
        />
      )}
      {action === "destination" && (
        <ChangerDestinationDesserte
          onFinished={() => {
            closeModal();
            onFinish();
          }}
          desserte={desserte}
          tourneeId={tourneeId}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

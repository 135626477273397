import { startOfTomorrow } from "date-fns";
import { useNotification } from "hooks";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeLoader, showLoader } from "reducers";
import { desserteService } from "services";
import { CODE_REFERENTIEL } from "types";
import { DesserteResource } from "types/transport.types";
import { dateFormater, dateParser } from "utils/date";
import { TransitDesserteModal } from "../form";

export const TransitDesserte: FC<{
  desserte: DesserteResource;
  onFinish: (data?: DesserteResource) => void;
  onClose: () => void;
}> = ({ desserte, onFinish, onClose }) => {
  const notification = useNotification();

  const [data, setData] = useState<DesserteResource | undefined>(undefined);
  const dispatch = useDispatch();
  const desserteId = desserte.id;

  useEffect(() => {
    dispatch(showLoader());
    desserteService
      .findById(desserteId)
      .then(setData)
      .then(() => {
        dispatch(closeLoader());
      });
  }, [desserteId, dispatch]);

  if (!data) {
    return null;
  }

  return (
    <TransitDesserteModal
      onBack={onClose}
      onFinished={() => {
        onFinish();
        onClose();
      }}
      onSave={(body) => {
        return desserteService
          .transit(desserte.id, {
            dateDepart: dateFormater.toBackPattern(body.dateDepart),
            signature: body.signature,
          })
          .then(() => {
            notification.success({
              title: "La desserte a été mise en Transit",
            });
          });
      }}
      defaultValues={{
        dateDepart:
          data.etatDesserte.id === CODE_REFERENTIEL.A_REPLANIFIER
            ? dateParser.toDate(data.dateDepart)
            : startOfTomorrow(),
        numero: data.numero,
        colis: data.colis,
        signature: {
          objet: `Transit du ${dateFormater.toBackPattern(
            new Date(),
            "dd/MM/yyyy HH:mm"
          )}`,
        },
      }}
      showSuccessNotification={false}
    />
  );
};

import { FC } from "react";
import { Grid } from "@mui/material";
import { PageTitle, ViewIcons } from "pages/common";
import { withForm, WrapperProps } from "hoc";
import {
  CentreAsyncSelectForm,
  Collapse,
  SelectReferentielAsycForm,
  TextInput,
} from "components";
import { useFormContext } from "react-hook-form";
import MainCard from "components/MainCard";
import { REFERENTIEL_TYPE } from "types";
import { selectHelper } from "utils/helpers";

const toBody = (data: any) => {
  const { typeIncident, ...rest } = data;

  return {
    ...rest,
    typeIncidentId: selectHelper.getValue(typeIncident),
  };
};

export const incidentConverter = {
  toBody,
};

//
const Form: FC<WrapperProps> = ({
  isEditMode,
  renderButtons,
  onBack,
  onSubmit,
}) => {
  const { watch } = useFormContext();

  return (
    <form onSubmit={onSubmit}>
      <PageTitle
        title={`${isEditMode ? "Modifier" : "Créer"} un incident`}
        subtitle={isEditMode ? `N° ${watch("numero")}` : ""}
        onBack={onBack}
        right={renderButtons()}
      />
      <MainCard>
        <Collapse
          title="Informations générales"
          subtitle="Renseigner les informations générales de l'incident"
          leftIcon={ViewIcons.infoGenerale}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <CentreAsyncSelectForm name="centre" label="Centre *" />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectReferentielAsycForm
                type={REFERENTIEL_TYPE.TYPE_CHANGEMENT}
                name="typeIncident"
                label="Type d'incident"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput name="libelle" label="Libellé *" />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="description"
                label="Description *"
                rows={4}
                multiline
              />
            </Grid>
          </Grid>
        </Collapse>
      </MainCard>
    </form>
  );
};

export const IncidentForm = withForm(Form);

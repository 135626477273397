import { Settings } from "@mui/icons-material";
import { filterSousMenu } from "menu-items";
import { FormattedMessage } from "react-intl";
import { UserModule } from "types";
import { LINKS } from "utils/constant";

const sousmenus = [
  {
    id: "setting-global",
    title: "Global",
    type: "item",
    url: LINKS.global,
  },
  {
    id: "billeteries",
    title: "Billeteries",
    type: "item",
    url: LINKS.billeteries.base,
  },
  {
    id: "lieux",
    title: <FormattedMessage id="menu.setting.lieux.title" />,
    type: "item",
    url: LINKS.lieux.base,
  },
  {
    id: "activites",
    title: <FormattedMessage id="menu.setting.activites.title" />,
    type: "item",
    url: LINKS.activites.base,
  },
  {
    id: "setting-factures",
    title: "Factures",
    type: "item",
    url: LINKS.settingFactures,
  },
  {
    id: "ustocks",
    title: <FormattedMessage id="menu.setting.ustocks.title" />,
    type: "item",
    url: LINKS.ustocks.base,
  },
  {
    id: "ssb",
    title: "SSB",
    type: "item",
    url: LINKS.ssb.base,
  },
];

export const buildUtilitaireMenu = (userModule: UserModule) => {
  const data = filterSousMenu(userModule, sousmenus);

  if (data.length) {
    return {
      id: "group-utilitaires",
      type: "group",
      children: [
        {
          id: "utilitaires",
          type: "collapse",
          title: <FormattedMessage id="menu.setting.title" />,
          icon: Settings,
          children: data,
        },
      ],
    };
  }
  return null;
};

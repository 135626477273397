import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingButton } from "components";
import { useNotification } from "hooks";
import { FC, useState } from "react";
import { tourneeService } from "services";
import { TourneeResource } from "types/transport.types";

export const DemarrerTournee: FC<{
  tournee?: TourneeResource;
  onFinish: (data?: TourneeResource) => void;
  onClose: () => void;
}> = ({ tournee, onFinish, onClose }) => {
  const [starting, setStarting] = useState<boolean>(false);
  const notification = useNotification();

  const demarrerTournee = (tourneeId: string) => {
    setStarting(true);
    tourneeService
      .demarrer(tourneeId)
      .then(({ data }) => {
        notification.success({
          title: "La tournée a bien été démarrée",
        });
        onFinish(data);
      })
      .catch((e) => {
        notification.warning({ title: e.message });
      })
      .finally(() => {
        setStarting(false);
        onClose();
      });
  };

  if (tournee) {
    return (
      <Dialog open onClose={onClose}>
        <DialogTitle>Confirmer démarrage</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous voulez vraiment demarrer la tournée&nbsp;
            <Typography component="span" fontWeight={600}>
              {tournee.numero}
            </Typography>
            &nbsp; ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <LoadingButton
            variant="contained"
            onClick={() => demarrerTournee(tournee.id)}
            autoFocus
            loading={starting}
          >
            Oui
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
};

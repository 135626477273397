import { FC } from "react";

// material-ui
import { Stack, Typography } from "@mui/material";

// project import

import logo from "assets/images/logo.png";
import { useSelector } from "react-redux";
//import Drapeau from "assets/images/rci.png";

interface Props {
  isIcon?: boolean;
}

const LogoMain: FC = () => {
  const setting = useSelector((state: any) => state.menu?.globalSetting?.value);

  return (
    <Stack justifyContent="center" alignItems="center" sx={{ marginTop: 1 }}>
      <a href="/">
        <img width="80px" height="65px" src={logo} alt="Burval" />
      </a>
      <Typography
        component="div"
        textAlign="center"
        marginTop={0.75}
        fontWeight={600}
      >
        {setting?.application?.nom || "Burval Corporate"}
        <Typography component="div" color="GrayText">
          {setting?.pays?.nom || ""}
        </Typography>
      </Typography>
    </Stack>
  );
};

const LogoIcon: FC = () => {
  return <img width="50px" src={logo} alt="Burval" />;
};

export const Logo = ({ isIcon }: Props) => (
  <Stack sx={{ margin: "0 auto" }}>
    {isIcon ? <LogoIcon /> : <LogoMain />}
  </Stack>
);

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
} from "@mui/material";
import { Dialog } from "@mui/material";
import { SelectSites } from "components";
import { Title } from "pages/utilitaires";
import { FC, useState } from "react";
import { SelectItem } from "types/form.type";

type GererTrajetProps = {
  closeModal: () => void;
  saveSites: (sites: SelectItem[]) => void;
};

/**
 * Ajout de sites
 * @param param0
 * @returns
 */
export const AddSite: FC<GererTrajetProps> = ({ closeModal, saveSites }) => {
  const [sites, setSites] = useState<SelectItem[]>([]);

  return (
    <Dialog maxWidth="sm" fullWidth open={true}>
      <Title onClose={() => {}}>Ajouter de nouveaux sites au trajet</Title>
      <Divider />
      <DialogContent
        sx={{
          backgroundColor: (t) => t.palette.background.default,
          padding: "1.5rem !important",
        }}
      >
        <SelectSites
          label="Nouveaux sites"
          name="sites"
          value={sites}
          afterSelected={(_, value) => setSites(value)}
        />
      </DialogContent>
      <DialogActions>
        <Box
          sx={{ marginTop: 0, marginLeft: "auto" }}
          display="flex"
          justifyContent="end"
        >
          <Button
            onClick={() => saveSites(sites)}
            variant="contained"
            disableElevation
          >
            Enregistrer
          </Button>

          <Button
            color="error"
            variant="contained"
            sx={{ ml: 1 }}
            onClick={closeModal}
            disableElevation
          >
            Annuler
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

import { FC } from "react";
import * as yup from "yup";
import { withForm, WrapperProps } from "hoc";
import MainCard from "components/MainCard";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";
import { CheckBox, Collapse, RadioGroupBox, TextInput } from "components";
import { useQuery } from "@tanstack/react-query";
import { referentielService } from "services";
import { REFERENTIEL_TYPE } from "types";
import { List, Receipt } from "@mui/icons-material";
import { PageTitle } from "pages/common";
import { useFormContext } from "react-hook-form";

const schema = yup.object({
  porteur: yup.string().required(),
  conditionPaiement: yup.string().required(),
  directeurGeneralDelegue: yup.string().required(),
  raisonSociale: yup.string().required(),
  siegeSocial: yup.string().required(),
  adresse: yup.string().required(),
  telephone: yup.string().required(),
  ncc: yup.string().required(),

  modeReglements: yup
    .array()
    .min(1, "Choisir au moins un mode de règlement")
    .required("Choisir au moins un mode de règlement")
    .nullable(),

  imprimeur: yup.object().shape({
    nom: yup
      .string()
      .when(["mode"], {
        is: (mode: string) => {
          return mode === "personne_designee";
        },
        then: () => yup.string().required(),
      })
      .nullable(),
  }),
});

const Form: FC<WrapperProps> = ({ renderButtons, onBack, onSubmit }) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext();

  const { data: modeReglements } = useQuery({
    queryKey: ["ReferentielModeReglements"],
    queryFn: () =>
      referentielService
        .findAll({ type: REFERENTIEL_TYPE.MODE_PAIEMENT })
        .then(({ data }) => data),
    staleTime: 60 * 1000 * 60, // 1heure
  });

  return (
    <form onSubmit={onSubmit}>
      <PageTitle
        title="Modification"
        subtitle="Modifier le paramétrage de la facturation du TDF"
        onBack={onBack}
        right={renderButtons()}
      />
      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Facture"
          subtitle="Paramétrer la facture"
          leftIcon={<Receipt />}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl error={!!errors.modeReglementss}>
                <FormLabel id="modeReglements" sx={{ marginRight: 2 }}>
                  Mode de règlement *
                </FormLabel>
                <FormGroup row>
                  {(modeReglements || []).map((modeReglement) => (
                    <FormControlLabel
                      control={
                        <CheckBox
                          name="modeReglements"
                          value={modeReglement.id}
                        />
                      }
                      label={modeReglement.name}
                      sx={{ marginRight: 3 }}
                      key={modeReglement.id}
                    />
                  ))}
                </FormGroup>
                {errors.modeReglements && (
                  <FormHelperText error id="password">
                    {errors.modeReglements?.message?.toString()}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextInput name="tva" label="TVA" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput name="porteur" label="Payer à l'ordre de *" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                name="conditionPaiement"
                label="Condition paiement *"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextInput
                name="directeurGeneralDelegue"
                label="Directeur général délégué *"
              />
            </Grid>
          </Grid>
        </Collapse>
      </MainCard>

      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Détail facture"
          subtitle="Paramétrer les détails de la facture"
          leftIcon={<List />}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextInput name="raisonSociale" label="Raison sociale *" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="siegeSocial" label="Siège social *" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput name="adresse" label="Adresse postale *" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput name="telephone" label="Numéro téléphone *" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput name="ncc" label="CNC/IFU/NIF/NCC *" />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="regime">Imprimer par *</FormLabel>
                <RadioGroupBox
                  name="imprimeur.mode"
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="personne_connectee"
                    control={<Radio />}
                    label="Utilisateur connecté"
                  />
                  <FormControlLabel
                    value="personne_designee"
                    control={<Radio />}
                    label="Personne designée "
                  />
                </RadioGroupBox>
              </FormControl>
            </Grid>
            {watch("imprimeur.mode") === "personne_designee" && (
              <Grid item xs={12} key="imprimeur.nom">
                <TextInput name="imprimeur.nom" label="Prénom et nom *" />
              </Grid>
            )}
          </Grid>
        </Collapse>
      </MainCard>

      <Box display="flex" justifyContent="flex-end">
        {renderButtons()}
      </Box>
    </form>
  );
};

export const SettingFactureForm = withForm(Form, schema);

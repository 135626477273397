import { Typography } from "@mui/material";
import { Table } from "components/table";
import { FC } from "react";
import { Prestataire } from "types/transport.types";
import { CORPS } from "utils/constant";

type ListPrestataireProps = {
  prestataires?: Prestataire[];
};

export const ListPrestataire: FC<ListPrestataireProps> = ({ prestataires }) => {
  const columns = [
    {
      Header: "Nom et prenom",
      accessor: "fullname",
    },

    {
      Header: "Matricule",
      accessor: "matricule",
    },
    {
      Header: "Numéro tel.",
      accessor: "telephone",
    },
    {
      Header: "Corps",
      accessor: "corps",
      Cell: ({ row: { original } }: any) => (
        <Typography>
          {CORPS.find((e) => e.value === original.corps)?.label || "-"}
        </Typography>
      ),
    },
  ];

  return <Table columns={columns} data={prestataires || []} />;
};

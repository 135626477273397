// types
import { MenuProps } from "types/menu";
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState: MenuProps = {
  openItem: ["dashboard"],
  openComponent: "buttons",
  drawerOpen: true,
  componentDrawerOpen: true,
  globalSetting: {},
  billetsEtPieces: {},
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    setGlobalSetting(state, action) {
      state.globalSetting = action.payload;
    },

    setBilletsEtPieces(state, action) {
      state.billetsEtPieces = action.payload;
    },
  },
});

export default menu.reducer;

export const {
  activeItem,
  activeComponent,
  openDrawer,
  openComponentDrawer,
  setGlobalSetting,
  setBilletsEtPieces,
} = menu.actions;

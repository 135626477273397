import { Grid } from "@mui/material";

import { useFormContext } from "react-hook-form";
import {
  SelectReferentielAsycForm,
  SelectSiteAsyncForm,
  SelectSituationGeographiqueAsycForm,
  SelectTypeVehiculeAsyncForm,
  SelectVilleAsycForm,
  SelectZoneAsycForm,
} from "components";
import { useContext, useMemo } from "react";
import { PlanifierTourneeContext } from "pages/tournees/context";
import { selectHelper } from "utils/helpers";
import { CODE_REFERENTIEL, REFERENTIEL_TYPE } from "types";

export const PlanificationDesserteFilters = () => {
  const { watch, setValue } = useFormContext();
  const { filtrer } = useContext(PlanifierTourneeContext);
  const centreId = watch("centre")?.value;
  const typeVehiculeId = watch("typeVehicule")?.value;
  const villeId = watch("ville")?.value;
  const situationGeographiqueId = watch("situationGeographique")?.value;
  const zoneId = watch("zone")?.value;
  const etatId = watch("etat")?.value;
  const siteId = watch("site")?.value;

  const villeDeps = useMemo(() => [centreId], [centreId]);

  const situationGeographiqueDeps = useMemo(
    () => [villeId, centreId],
    [villeId, centreId]
  );

  const zoneDeps = useMemo(
    () => [situationGeographiqueId, villeId, centreId],
    [situationGeographiqueId, villeId, centreId]
  );

  return (
    <Grid container marginBottom={2} spacing={2}>
      <Grid item xs={12} md={3}>
        <SelectReferentielAsycForm
          label="Etat desserte"
          name="etat"
          type={REFERENTIEL_TYPE.ETAT}
          codes={[CODE_REFERENTIEL.INIT, CODE_REFERENTIEL.TRANSIT]}
          size="small"
          afterSelected={(_, selected) => {
            filtrer({
              typeVehiculeId,
              villeId,
              situationGeographiqueId,
              zoneId,
              etatId: selectHelper.getValue(selected),
              siteId,
            });
          }}
          disabled={!centreId}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <SelectSiteAsyncForm
          label="Site"
          name="site"
          size="small"
          afterSelected={(_, selected) => {
            filtrer({
              siteId: selectHelper.getValue(selected),
              villeId,
              situationGeographiqueId,
              zoneId,
              etatId,
              typeVehiculeId,
            });
          }}
          disabled={!centreId}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <SelectTypeVehiculeAsyncForm
          label="Type véhicule"
          name="typeVehicule"
          size="small"
          afterSelected={(_, selected) => {
            filtrer({
              typeVehiculeId: selectHelper.getValue(selected),
              villeId,
              situationGeographiqueId,
              zoneId,
              etatId,
              siteId,
            });
          }}
          disabled={!centreId}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectVilleAsycForm
          label="Ville"
          name="ville"
          size="small"
          queryParams={{ centreId }}
          resetDependencies={villeDeps}
          afterSelected={(_, selected) => {
            filtrer({
              villeId: selectHelper.getValue(selected),
              typeVehiculeId,
              etatId,
              siteId,
            });
            setValue("situationGeographique", null);
            setValue("zone", null);
          }}
          disabled={!centreId}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectSituationGeographiqueAsycForm
          label="Situation géographique"
          name="situationGeographique"
          size="small"
          queryParams={{ villeId, centreId }}
          resetDependencies={situationGeographiqueDeps}
          afterSelected={(_, selected) => {
            filtrer({
              villeId,
              typeVehiculeId,
              situationGeographiqueId: selectHelper.getValue(selected),
              etatId,
              siteId,
            });
            setValue("zone", null);
          }}
          disabled={!centreId}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectZoneAsycForm
          label="Zone"
          name="zone"
          size="small"
          queryParams={{
            situationGeographiqueId,
            centreId,
          }}
          resetDependencies={zoneDeps}
          afterSelected={(_, selected) => {
            filtrer({
              villeId,
              typeVehiculeId,
              situationGeographiqueId,
              zoneId: selectHelper.getValue(selected),
              etatId,
              siteId,
            });
          }}
          disabled={!centreId}
        />
      </Grid>
    </Grid>
  );
};

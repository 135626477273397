import { Grid } from "@mui/material";
import {
  CentreAsyncSelectForm,
  SelectTourneeAsyncForm,
  TextInput,
} from "components";
import { withForm, WrapperProps } from "hoc";
import { UtilitaireModal } from "pages/utilitaires";
import { FC } from "react";
import { jonctionService } from "services";
import { DesserteResource, JonctionResource } from "types/transport.types";
import { selectHelper } from "utils/helpers";

import * as yup from "yup";
import { SelectDessertes } from "components/form/asycSelect/SelectDessertes";
import { useFormContext } from "react-hook-form";

const ModifierJonctionForm: FC<WrapperProps> = (props) => {
  const { watch } = useFormContext();
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title="Modification de jonction"
      maxWidth="md"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <CentreAsyncSelectForm name="centre" label="Centre *" disabled />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectTourneeAsyncForm name="tournee" label="Tournée *" disabled />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextInput name="lieu" label="Lieu *" />
        </Grid>

        <Grid item xs={12}>
          <SelectDessertes
            name="dessertes"
            label="Dessertes *"
            defaultOptions={watch("tourneeDessertes")}
            limitTags={5}
          />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

const ModifierJonctionModal = withForm(
  ModifierJonctionForm,
  yup.object({
    dessertes: yup.array().min(1).required("Au moins une desserte"),
    lieu: yup.string().required().nullable(),
  })
);

type ModifierJonctionProps = {
  jonction: JonctionResource;
  tourneeDessertes: DesserteResource[];
  closeModal: () => void;
  onFinished: (data: JonctionResource) => void;
};

export const ModifierJonction: FC<ModifierJonctionProps> = ({
  jonction,
  tourneeDessertes,
  closeModal,
  onFinished,
}) => {
  const modifierEquipage = (data: Record<string, any>) => {
    return jonctionService.update(jonction.id, {
      lieu: data.lieu,
      dessertes: data.dessertes.map(selectHelper.getValue),
    });
  };

  return (
    <ModifierJonctionModal
      onSave={modifierEquipage}
      onBack={closeModal}
      onFinished={onFinished}
      defaultValues={{
        lieu: jonction.lieu,
        centre: selectHelper.buildCentre(jonction.centre),
        tournee: selectHelper.build(
          jonction.tourneeJonction.numero,
          jonction.tourneeJonction.id
        ),
        dessertes: tourneeDessertes
          .filter((item) => jonction.dessertes.includes(item.id.toString()))
          .map((item) => ({
            label: `${item.siteDepart.nom} / ${item.siteArrivee.nom}`,
            value: item.id,
            desserte: item,
          })),
        tourneeDessertes,
      }}
    />
  );
};

import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Button, IconButton, Tooltip, useTheme } from "@mui/material";

// assets
import { MontantFormatText, TableSimple } from "components";
import { PageTitle } from "pages/common";
import { parametrageService } from "services";

import { useModal } from "hooks";
import { CloseOutlined, EditTwoTone } from "@ant-design/icons";
import { BilleterieModal } from "./BilleterieModal";
import { ParametrageResource } from "types";
import {
  deleteElement,
  // deleteElement,
  numberHelper,
  updateElement,
  uuid,
} from "utils/helpers";

const INITIAL = { value: 0 };

type ListBilleterieProps = {
  code: string;
  libelle: string;
  description: string;
  btn: {
    label: string;
  };
};

const ListBilleterie: FC<ListBilleterieProps> = (props) => {
  const theme = useTheme();
  const [parametrage, setParametrage] = useState<
    ParametrageResource | undefined
  >();
  const [defaultValues, setDefaultValues] = useState<any | undefined>(INITIAL);
  const modal = useModal();
  const [loading, setLoading] = useState<boolean>(true);

  const loadData = useCallback(() => {
    setLoading(true);
    parametrageService
      .findAll({
        code: props.code,
      })
      .then(({ data }) => setParametrage(data[0]))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const save = (data: any) => {
    if (!parametrage) {
      return Promise.reject();
    }

    let value;

    const index = (parametrage.value as any).findIndex(
      (e: any) => data.id === e.id
    );

    if (index < 0) {
      value = [
        ...(parametrage.value as any[]),
        { id: uuid(), valeur: numberHelper.trim(data.valeur) },
      ];
    } else {
      value = updateElement(
        parametrage.value as any[],
        { ...data, valeur: numberHelper.trim(data.valeur) },
        index
      );
    }

    return parametrageService.update(parametrage.id, {
      value,
    });
  };

  const onDelete = (index: any) => {
    if (!parametrage) {
      return Promise.reject();
    }

    const value = deleteElement(parametrage.value as any[], index);

    parametrageService
      .update(parametrage.id, {
        value,
      })
      .then(() => {
        modal.close();
        loadData();
      });
  };

  const onEdit = (original: any) => {
    setDefaultValues({
      id: original.id,
      valeur: original.valeur,
    });
    modal.show();
  };

  const closeModal = () => {
    modal.close();
    setDefaultValues(INITIAL);
  };

  const onFinished = (element: any) => {
    loadData();
    closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Valeur",
        accessor: "valeur",
        Cell: ({ row: { original } }: any) => (
          <MontantFormatText value={original.valeur} />
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",

        Cell: ({ row: { original, index } }: any) => (
          <>
            <Tooltip title="Modifier">
              <IconButton
                color="error"
                size="large"
                onClick={() => onEdit(original)}
              >
                <EditTwoTone twoToneColor={theme.palette.info.main} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Supprimer">
              <IconButton
                color="error"
                size="large"
                onClick={() => onDelete(index)}
              >
                <CloseOutlined twoToneColor={theme.palette.error.main} />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parametrage]
  );

  if (!parametrage) {
    return null;
  }

  return (
    <>
      <PageTitle
        title={props.libelle}
        subtitle={props.description}
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button
            variant="contained"
            onClick={() => {
              modal.show();
              setDefaultValues(INITIAL);
            }}
          >
            {props.btn.label}
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={(parametrage.value as any) || []}
        loading={loading}
        withPagination
      />
      {modal.open && (
        <BilleterieModal
          onBack={closeModal}
          onFinished={onFinished}
          onSave={save}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default ListBilleterie;

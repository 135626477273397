import { FC, useMemo, useState } from "react";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Etat, LoadingButton } from "components";
import {
  DesserteResource,
  EquipageResource,
  TrajetResource,
} from "types/transport.types";
import { Dialog } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { useNotification } from "hooks";
import { Typography } from "@mui/material";
import { DesserteSens } from "pages/dessertes";
import { CODE_REFERENTIEL } from "types";
import { equipageService } from "services";

type PasserSiteProps = {
  trajet: TrajetResource;
  dessertes: DesserteResource[];
  equipage: EquipageResource;
  closeModal: () => void;
  onFinished: () => void;
};

export const PasserSite: FC<PasserSiteProps> = ({
  trajet,
  dessertes,
  equipage,
  closeModal,
  onFinished,
}) => {
  const [isSaving, setSaving] = useState<boolean>(false);
  const notification = useNotification();

  const passerSite = () => {
    setSaving(true);
    equipageService
      .passerSite(equipage.id, trajet.id)
      .then(() => {
        notification.success({
          title: "Le passage sur le site a bien été annulée",
        });
        onFinished();
      })
      .catch((e) => {
        notification.error({ title: e.message });
      })
      .finally(() => {
        setSaving(false);
        closeModal();
      });
  };

  const equipageDessertes = useMemo(() => {
    return dessertes
      .filter(({ id }) => {
        return equipage.dessertes.some((desserte) => id === desserte.id);
      })
      .filter((desserte) => {
        if (
          [CODE_REFERENTIEL.INIT, CODE_REFERENTIEL.A_REPLANIFIER].includes(
            desserte.etatDesserte.id as any
          )
        ) {
          return false;
        }
        const typeDesserte = desserte.typeDesserte?.id;
        if (
          typeDesserte === CODE_REFERENTIEL.RAMASSAGE &&
          trajet.idSite === desserte.siteDepart.id
        ) {
          return desserte.etatDesserte?.id !== CODE_REFERENTIEL.EN_COURS;
        }

        if (
          typeDesserte === CODE_REFERENTIEL.APPRO &&
          trajet.idSite === desserte.siteArrivee.id
        ) {
          return desserte.etatDesserte?.id !== CODE_REFERENTIEL.LIVRE;
        }

        return false;
      });
  }, [equipage.dessertes, dessertes, trajet]);

  return (
    <Dialog maxWidth="sm" open onClose={closeModal}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="warning" variant="border">
            Vous êtes sur le point de marquer le site&nbsp;
            <strong>{trajet.nomSite}</strong>
            &nbsp;comme visté par l'équipage.
          </Alert>
          {!!equipageDessertes.length && (
            <>
              <Typography variant="h5" sx={{ marginTop: 3 }}>
                Les dessertes suivantes non pas été traitées
              </Typography>
              <List>
                {equipageDessertes.map((desserte) => (
                  <ListItem
                    divider
                    key={desserte.id}
                    sx={{ "&:last-child": { border: 0 } }}
                    secondaryAction={
                      <Etat
                        label={desserte.etatDesserte?.name || ""}
                        value={desserte.etatDesserte?.id}
                      />
                    }
                  >
                    <ListItemText
                      primary={<DesserteSens desserte={desserte} />}
                      secondaryTypographyProps={{
                        component: "div",
                      }}
                      secondary={`N°${desserte.numero} / ${desserte.typeDesserte?.name}`}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Annuler</Button>
        <LoadingButton
          variant="contained"
          onClick={passerSite}
          autoFocus
          loading={isSaving}
          color="primary"
        >
          Enregistrer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import { GlobalLoader, Notification } from "components";
import Locales from "components/Locales";
import { Navigation } from "navigation";
import { ThemeCustomization } from "./themes";

import * as yup from "yup";

import fr from "javascript-time-ago/locale/fr.json";
import TimeAgo from "javascript-time-ago";

import "simplebar/src/simplebar.css";
import "App.css";
import { AppContextProvider } from "contexts";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

yup.setLocale({
  mixed: {
    required: "Ce champs est obligatoire",
  },
  string: {
    email: "Doit être un email valide",
  },
});

TimeAgo.addDefaultLocale(fr);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeCustomization>
        <Locales>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={frLocale}
          >
            <AppContextProvider>
              <Navigation />
              <Notification />
              <GlobalLoader />
            </AppContextProvider>
          </LocalizationProvider>
        </Locales>
      </ThemeCustomization>
    </QueryClientProvider>
  );
};

export default App;

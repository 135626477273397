import {
  AccessTimeSharp,
  AccountBalance,
  AccountBalanceWalletOutlined,
  Adjust,
  AlternateEmail,
  Apartment,
  Article,
  BugReportOutlined,
  Cancel,
  CardMembership,
  Commit,
  Delete,
  DepartureBoard,
  Event,
  Flag,
  GradingRounded,
  Groups,
  InfoOutlined,
  Inventory,
  Lock,
  ManageAccounts,
  ModeEdit,
  More,
  NextPlan,
  Notifications,
  Paid,
  PeopleAlt,
  PictureAsPdf,
  PinDrop,
  PlayArrow,
  PlayCircleFilled,
  Receipt,
  ReportProblemRounded,
  RouteRounded,
  Rule,
  Sell,
  Signpost,
  Visibility,
  Work,
} from "@mui/icons-material";

export const IncidentIcon = ReportProblemRounded;
export const DemarrerIcon = PlayCircleFilled;
export const TerminerIcon = GradingRounded;
export const TrajetIcon = RouteRounded;
export const GenerateIcon = Commit;
export const PdfIcon = PictureAsPdf;
export const AnnulerIcon = Cancel;
export const PlanificationIcon = Event;
export const SupprimerIcon = Delete;
export const ModifierIcon = ModeEdit;
export const AfficherIcon = Visibility;
export const BeneficiaireIcon = Groups;
export const PaiementIcon = CardMembership;

export const ViewIcons = {
  infoGenerale: <InfoOutlined color="primary" />,
  depart: <DepartureBoard color="primary" />,
  arrivee: <Flag color="primary" />,
  facture: <Receipt color="primary" />,
  contact: <AlternateEmail color="primary" />,
  adress: <Signpost color="primary" />,
  situationGeographique: <PinDrop color="primary" />,
  contrat: <Rule color="primary" />,
  prestation: <Work color="primary" />,
  vente: <Sell color="primary" />,
  frequence: <AccessTimeSharp color="primary" />,
  client: <Apartment color="primary" />,
  equipe: <Groups color="primary" />,
  desserte: <Adjust color="primary" />,
  event: <Event color="primary" />,
  escale: <NextPlan color="primary" />,
  prestataire: <PeopleAlt color="primary" />,
  jonction: <ManageAccounts color="primary" />,
  paid: <Paid color="primary" />,
  colis: <Inventory color="primary" />,
  demarrer: <PlayArrow color="primary" />,
  lock: <Lock color="primary" />,
  notification: <Notifications color="primary" />,
  incident: <ReportProblemRounded color="primary" />,
  route: <RouteRounded color="primary" />,
  article: <Article color="primary" />,
  complementaire: <More color="primary" />,
  ticket: <BugReportOutlined color="primary" />,
  ssb: <AccountBalance color="primary" />,
  cassette: <AccountBalanceWalletOutlined color="primary" />,
};

import { FC, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "components";
import { TrajetResource } from "types/transport.types";
import { equipageService } from "services";
import { Dialog } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { useNotification } from "hooks";

type AnnulerPassageProps = {
  trajet: TrajetResource;
  equipageId: string;
  closeModal: () => void;
  onFinished: () => void;
};

export const AnnulerPassage: FC<AnnulerPassageProps> = ({
  trajet,
  equipageId,
  closeModal,
  onFinished,
}) => {
  const [isSaving, setSaving] = useState<boolean>(false);
  const notification = useNotification();

  const annuler = () => {
    setSaving(true);
    equipageService
      .annulerTrajet(equipageId, trajet.id)
      .then(() => {
        notification.success({
          title: "Le passage sur le site a bien été annulée",
        });
        onFinished();
      })
      .catch((e) => {
        notification.error({ title: e.message });
      })
      .finally(() => {
        setSaving(false);
        closeModal();
      });
  };
  return (
    <Dialog maxWidth="sm" open onClose={closeModal}>
      <DialogTitle>Confirmer annulation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vous voulez vraiment annuler le passage sur le site {trajet.nomSite} ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Annuler</Button>
        <LoadingButton
          variant="contained"
          onClick={annuler}
          autoFocus
          loading={isSaving}
          color="error"
        >
          Oui
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { withForm, WrapperProps } from "hoc";
import { UtilitaireModal } from "pages/utilitaires";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { TextInput, ThousandFormat, TimeInput } from "components";
import {
  ColisResource,
  DesserteResource,
  TrajetResource,
} from "types/transport.types";
import { numberHelper } from "utils/helpers";
import * as yup from "yup";
import { dateFormater } from "utils/date";
import { colisService, equipageService } from "services";
import { ListDesserteColis } from "pages/dessertes/view/ListDesserteColis";
import { CODE_REFERENTIEL } from "types";
import { TraitementHeader } from "./TraitementHeader";
import { useDispatch } from "react-redux";
import { closeLoader, showLoader } from "reducers";

const schema = yup.object({
  heure: yup.date().required().nullable(),
  signature: yup.object({
    signataire: yup.string().required().nullable(),
    objet: yup.string().required().nullable(),
  }),
});

const Form: FC<WrapperProps> = (props) => {
  const { watch } = useFormContext();
  const dessertes = watch("dessertes") || [];
  const nomSite = watch("trajet")?.nomSite;
  const hasDessertes = !!dessertes.length;

  const dispatch = useDispatch();
  const [colis, setColis] = useState<ColisResource[] | null>(null);

  useEffect(() => {
    dispatch(showLoader());
    colisService
      .findAll({ desserteId: dessertes.map((e: any) => e.id).join(",") })
      .then(({ data }) => data)
      .then(setColis)
      .catch(() => setColis([]))
      .finally(() => dispatch(closeLoader()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!colis) {
    return null;
  }

  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title="Livraison colis"
      maxWidth="md"
    >
      <Grid container spacing={3}>
        {hasDessertes ? (
          <>
            <Grid item xs={12}>
              <Alert severity="warning" variant="border">
                <AlertTitle>
                  Livrer les colis sur <strong>{nomSite}</strong>
                </AlertTitle>
              </Alert>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextInput label="Objet *" name="signature.objet" />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextInput label="Signataire *" name="signature.signataire" />
            </Grid>

            <Grid item xs={12} md={2}>
              <TimeInput label="Heure *" name="heure" size="small" />
            </Grid>

            <Grid item xs={12} md={2}>
              <TextInput
                label="Kilométrage"
                name="km"
                size="small"
                InputProps={{
                  inputComponent: ThousandFormat as any,
                }}
              />
            </Grid>
            {dessertes.map((desserte: any, desserteIndex: number) => {
              const isSelected = watch(`dessertes.${desserteIndex}.selected`);
              return (
                <Grid item xs={12} key={desserte.id}>
                  <TraitementHeader desserteIndex={desserteIndex} />
                  {isSelected ? (
                    <ListDesserteColis
                      colis={colis.filter((c) => c.desserteId === desserte.id)}
                    />
                  ) : (
                    <Alert severity="warning">
                      Cette desserte ne sera pas traité dans cette livraison
                    </Alert>
                  )}
                </Grid>
              );
            })}
          </>
        ) : (
          <Grid item xs={12}>
            <Alert severity="warning" variant="border">
              Aucune livraison non traitée n'a été trouvée sur&nbsp;
              <strong>{nomSite}</strong>
            </Alert>
          </Grid>
        )}
      </Grid>
    </UtilitaireModal>
  );
};

const LivrerColisModal = withForm(Form, schema);

type LivrerColisProps = {
  dessertes: DesserteResource[];
  trajet: TrajetResource;
  equipageId: string;
  closeModal: () => void;
  onFinished: () => void;
};

export const LivrerColis: FC<LivrerColisProps> = ({
  dessertes,
  trajet,
  equipageId,
  closeModal,
  onFinished,
}) => {
  const dessertesALivrer = dessertes
    .filter(
      (desserte) =>
        trajet.idSite === desserte.siteArrivee.id &&
        [CODE_REFERENTIEL.EN_COURS, CODE_REFERENTIEL.TRANSIT].includes(
          desserte.etatDesserte?.id as any
        )
    )
    .map((desserte) => {
      return {
        id: desserte.id,
        numero: desserte.numero,
        depart: desserte.siteDepart.nom,
        arrivee: desserte.siteArrivee.nom,
        colis: desserte.colis,
        selected: true,
      };
    });

  const livrer = (data: Record<string, any>) => {
    return equipageService.livrer(equipageId, trajet.id, {
      dessertes: data.dessertes
        .filter((desserte: any) => !!desserte.selected)
        .map((desserte: any) => ({
          id: desserte.id,
          colis: desserte.colis,
        })),
      heureDepart: dateFormater.toBackTime(data.heure),
      kmDepart: numberHelper.trim(data.km),
      signature: data.signature,
    });
  };

  return (
    <LivrerColisModal
      onBack={closeModal}
      onSave={livrer}
      onFinished={onFinished}
      defaultValues={{
        dessertes: dessertesALivrer,
        heure: new Date(),
        trajet,
        signature: {
          objet: `Livraison du ${dateFormater.toBackPattern(
            new Date(),
            "dd/MM/yyyy HH:mm"
          )}`,
        },
      }}
    />
  );
};

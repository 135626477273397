import { FC } from "react";
import { Button, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { Collapse, TextInput, ThousandFormat } from "components";
import { AddCircle, Delete, Inventory, ListAlt } from "@mui/icons-material";
import { uuid } from "utils/helpers";

type DesserteColisFormProps = {
  colisIndex: number;
  base?: string;
  deleteColis: (colisIndex: number) => void;
  updateColis: (colisIndex: number, colis: any) => void;
};

export const DesserteColisForm: FC<DesserteColisFormProps> = ({
  colisIndex,
  base = "",
  deleteColis,
  updateColis,
}) => {
  const { watch } = useFormContext();

  const colis = watch(`${base}colis.${colisIndex}`);
  const sousColis = watch(`${base}colis.${colisIndex}.sousColis`) || [];

  const addSousColis = () => {
    updateColis(colisIndex, {
      ...colis,
      sousColis: [
        ...(colis.sousColis || []),
        { montant: "", numero: "", key: uuid() },
      ],
    });
  };

  const deleteSousColis = (sousColisIndex: number) => {
    const sousColis = colis.sousColis || [];

    updateColis(colisIndex, {
      ...colis,
      sousColis: [
        ...sousColis.slice(0, sousColisIndex),
        ...sousColis.slice(sousColisIndex + 1),
      ],
    });
  };

  const numero = watch(`${base}colis.${colisIndex}.numero`);

  const addSousColisButton = (
    <Button
      size="small"
      variant="contained"
      color="primary"
      disableElevation
      sx={{ marginTop: 0, marginLeft: "auto" }}
      onClick={addSousColis}
      startIcon={<AddCircle />}
    >
      sous colis
    </Button>
  );

  return (
    <Collapse
      title={`Colis ${numero ? `N° ${numero}` : colisIndex + 1}`}
      subtitle="Liste des colis et sous colis"
      actions={
        <Tooltip title="Supprimer le colis">
          <IconButton color="error" onClick={() => deleteColis(colisIndex)}>
            <Delete />
          </IconButton>
        </Tooltip>
      }
      leftIcon={<Inventory color="primary" />}
      headerBottom={2}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Numéro colis *"
            name={`${base}colis.${colisIndex}.numero`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Montant *"
            name={`${base}colis.${colisIndex}.montant`}
            InputProps={{
              inputComponent: ThousandFormat as any,
            }}
          />
        </Grid>
      </Grid>
      {sousColis.length ? (
        <>
          <Collapse
            title="Sous colis"
            subtitle="Liste des sous colis associée au colis"
            sx={{ marginTop: 2 }}
            headerBottom={2}
            leftIcon={<ListAlt color="primary" />}
          >
            {sousColis.map((field: any, i: number) => {
              return (
                <Stack
                  key={field.key}
                  flexDirection="row"
                  sx={{ marginBottom: 2 }}
                >
                  <TextInput
                    label="Numéro sous colis *"
                    name={`${base}colis.${colisIndex}.sousColis.${i}.numero`}
                    sx={{ marginRight: 1 }}
                  />
                  <TextInput
                    label="Montant sous colis *"
                    name={`${base}colis.${colisIndex}.sousColis.${i}.montant`}
                    sx={{ marginRight: 1 }}
                    InputProps={{
                      inputComponent: ThousandFormat as any,
                    }}
                  />
                  <Tooltip title="Supprimer le sous colis">
                    <IconButton
                      size="large"
                      color="error"
                      onClick={() => deleteSousColis(i)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Stack>
              );
            })}
            <Stack direction="row">{addSousColisButton}</Stack>
          </Collapse>
        </>
      ) : (
        <Stack direction="row" sx={{ marginTop: 2 }}>
          {addSousColisButton}
        </Stack>
      )}
    </Collapse>
  );
};

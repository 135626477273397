import { FC, useContext } from "react";
import { Alert, AlertTitle, Grid, Grow } from "@mui/material";
import {
  CentreAsyncSelectForm,
  Collapse,
  DateInput,
  TimeInput,
} from "components";

import { useFormContext } from "react-hook-form";
import { ViewIcons } from "pages/common";
import { PlanifierTourneeContext } from "../context";

export const TourneeInfoGeneralForm: FC = () => {
  const { watch, setValue } = useFormContext();
  const { setDessertesAPlanifier } = useContext(PlanifierTourneeContext);

  return (
    <Collapse
      title="Information générale"
      subtitle="Renseigner les informations générales de la tournée"
      leftIcon={ViewIcons.infoGenerale}
    >
      <Grid container spacing={3}>
        {watch("dateDepart") && watch("centre") ? null : (
          <Grow in>
            <Grid item xs={12}>
              <Alert color="warning" variant="border">
                <AlertTitle>
                  Selectionnez un <strong>centre</strong> et une&nbsp;
                  <strong>date</strong> pour afficher les dessertes
                </AlertTitle>
              </Alert>
            </Grid>
          </Grow>
        )}
        <Grid item xs={12} md={4}>
          <CentreAsyncSelectForm
            label="Centre *"
            name="centre"
            size="small"
            afterSelected={(_, value) => {
              setValue("typeVehicule", null);
              setValue("ville", null);
              setValue("situationGeographique", null);
              setValue("zone", null);
              setDessertesAPlanifier([]);
              setValue("equipePrincipale.centre", value);
              setValue("equipePrincipale.vehicule", null);
              setValue("equipePrincipale.chefDeBord", null);
              setValue("equipePrincipale.agentGarde", null);
              setValue("equipePrincipale.chauffeur", null);
              setValue("equipeJonction", {});
              setValue("hasJonction", undefined);
            }}
            withRestrictions
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <DateInput label="Date départ *" name="dateDepart" size="small" />
        </Grid>

        <Grid item xs={12} md={4}>
          <TimeInput label="Heure départ" name="heureDepart" size="small" />
        </Grid>
      </Grid>
    </Collapse>
  );
};

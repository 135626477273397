import { FC, useEffect, useState } from "react";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
} from "@mui/material";
import {
  AutoCompleteAsyncHookForm,
  CentreAsyncSelectForm,
  DateInput,
  RadioGroupBox,
  SelectClientAsyncForm,
  SelectReferentielAsycForm,
  SelectSiteAsyncForm,
  SelectTypeVehiculeAsyncForm,
  TimeInput,
} from "components";
import { CODE_REFERENTIEL, REFERENTIEL_TYPE } from "types";
import { useFormContext } from "react-hook-form";
import { referentielService } from "services";
import { SelectItem } from "types/form.type";
import { dateParser } from "utils/date";

type DesserteInfoGeneraleFormProps = {
  setSitePrestation: (siteFacture?: any) => Promise<any>;
};

export const DesserteInfoGeneraleForm: FC<DesserteInfoGeneraleFormProps> = ({
  setSitePrestation,
}) => {
  const { watch, setValue, getFieldState } = useFormContext();
  const [provenances, setProvenances] = useState<SelectItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const id = watch("id");
  const siteDepart = watch("siteDepart");
  const siteArrivee = watch("siteArrivee");
  const sourceSiteAFacturer = watch("sourceSiteAFacturer");

  useEffect(() => {
    referentielService
      .findAll({
        type: REFERENTIEL_TYPE.PROVENANCE_DESSERTE,
        code: [CODE_REFERENTIEL.APPEL_TELEPHONIQUE, CODE_REFERENTIEL.MAIL].join(
          ","
        ),
      })
      .then(({ data }) => {
        const items = data.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        if (!id) {
          setValue(
            "provenance",
            items.find(
              (item) => item.value === CODE_REFERENTIEL.APPEL_TELEPHONIQUE
            )
          );
        }
        return items;
      })
      .then(setProvenances);
  }, [id, setValue]);

  useEffect(() => {
    const isTouched =
      getFieldState("siteDepart").isTouched ||
      getFieldState("siteArrivee").isTouched;

    if (isTouched && sourceSiteAFacturer !== undefined) {
      let site =
        sourceSiteAFacturer === "expedition"
          ? siteDepart
          : sourceSiteAFacturer === "destination"
          ? siteArrivee
          : null;
      if (site) {
        setLoading(true);
        setSitePrestation(site).finally(() => {
          setLoading(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDepart, siteArrivee, sourceSiteAFacturer, setValue, getFieldState]);

  const setHeurePassage = () => {
    const isAppro = watch("typeDesserte")?.value === CODE_REFERENTIEL.APPRO;
    const { heureDebutPassage, heureFinPassage } =
      (isAppro ? watch("siteArrivee") : watch("siteDepart")) || {};

    setValue(
      "heureDebutPassage",
      dateParser.createDateFromTime(heureDebutPassage)
    );
    setValue("heureFinPassage", dateParser.createDateFromTime(heureFinPassage));
  };

  const renderCommonInputs = () => {
    return (
      <>
        <Grid item xs={12} md={3}>
          <SelectSiteAsyncForm
            name="siteFacture"
            label="Site à facturer *"
            disabled={!watch("id") && watch("sourceSiteAFacturer") !== "autre"}
            afterSelected={(_, site) => {
              setLoading(true);
              setSitePrestation(site).finally(() => {
                setLoading(false);
              });
            }}
            queryParams={{
              transportDeFond: "1",
              hasClient: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AutoCompleteAsyncHookForm
            label="Provenance *"
            name="provenance"
            defaultOptions={provenances}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <SelectReferentielAsycForm
            label="Type desserte *"
            name="typeDesserte"
            type={REFERENTIEL_TYPE.TYPE_DESSERTE}
            afterSelected={() => setHeurePassage()}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectTypeVehiculeAsyncForm
            name="typeVehicule"
            label="Type de véhicule *"
            size="small"
          />
        </Grid>
      </>
    );
  };

  const renderCreatedInputs = () => {
    return (
      <>
        <Grid item xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel sx={{ marginBottom: 0.5 }} id="siteSource">
              Site à facturer
            </FormLabel>
            <RadioGroupBox
              name="sourceSiteAFacturer"
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormControlLabel
                value="expedition"
                control={<Radio />}
                label="Site expéditeur"
                sx={{ paddingLeft: 1.5 }}
              />
              <FormControlLabel
                value="destination"
                control={<Radio />}
                label="Site destination"
                sx={{ paddingLeft: 1.5 }}
              />
              <FormControlLabel
                value="autre"
                control={<Radio />}
                label="Autre"
                sx={{ paddingLeft: 1.5 }}
              />
              {loading && <CircularProgress size={20} />}
            </RadioGroupBox>
          </FormControl>
        </Grid>
        {renderCommonInputs()}
      </>
    );
  };

  const renderEditedInputs = () => {
    return renderCommonInputs();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <CentreAsyncSelectForm
          label="Centre *"
          name="centre"
          withRestrictions
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <SelectClientAsyncForm label="Client *" name="client" />
      </Grid>

      <Grid item xs={12} md={3}>
        <SelectSiteAsyncForm
          label="Site expédition *"
          name="siteDepart"
          disabled={watch("hasTournee")}
          afterSelected={() => setHeurePassage()}
          queryParams={{
            transportDeFond: "1",
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectSiteAsyncForm
          label="Site destination *"
          name="siteArrivee"
          size="small"
          disabled={watch("hasTournee")}
          afterSelected={() => setHeurePassage()}
          queryParams={{
            transportDeFond: "1",
          }}
        />
      </Grid>

      {watch("id") ? renderEditedInputs() : renderCreatedInputs()}

      <Grid item xs={12} md={6}>
        <DateInput
          label="Date expédition *"
          name="dateDepart"
          dateWrapperProps={{
            minDate: new Date(),
          }}
          disabled={watch("hasTournee")}
          size="small"
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TimeInput name="heureDebutPassage" label="Heure de début passage" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TimeInput name="heureFinPassage" label="Heure de fin passage" />
      </Grid>

      {/* {watch("id") && (
        <Grid item xs={12}>
          <FormControlLabel
            control={<SwitchBox name="escale" color="success" />}
            label="En escale"
          />
        </Grid>
      )} */}
    </Grid>
  );
};

import { withForm } from "hoc";
import { FC } from "react";
import { equipageService } from "services";
import { EquipageResource } from "types/transport.types";
import { selectHelper } from "utils/helpers";

import * as yup from "yup";
import { buildEquipage } from "../form";
import { equipageValidator } from "../form/common/tourneeSchema";
import { formatEquipage } from "../view/equipage/EquipageItem";
import { ModifierEquipageForm } from "./ModifierEquipage";

const ChangerEquipageModal = withForm(
  ModifierEquipageForm,
  yup.object({
    ...equipageValidator,
    lieu: yup.string().required(),
    motifChangement: yup.object().required().nullable(),
  })
);

type ModifierEquipageProps = {
  equipage: EquipageResource;
  closeModal: () => void;
  onFinished: (data: EquipageResource) => void;
};

export const ChangerEquipage: FC<ModifierEquipageProps> = ({
  equipage,
  closeModal,
  onFinished,
}) => {
  const changementEquipage = (data: Record<string, any>) => {
    return equipageService.changer(equipage.id, buildEquipage(data));
  };

  return (
    <ChangerEquipageModal
      onSave={changementEquipage}
      onBack={closeModal}
      onFinished={onFinished}
      defaultValues={{
        centre: selectHelper.buildCentre(equipage.centre),
        disabledCentre: true,
        mode: "changement",
        title: `Changer équipage ${formatEquipage(equipage)}`,
      }}
    />
  );
};

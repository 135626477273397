import { Typography } from "@mui/material";
import { CellResourceName, Table } from "components/table";
import { FC } from "react";
import { IncidentResource } from "types/incidents.types";
import { LINKS } from "utils/constant";
import { buildUserShortName } from "utils/helpers";

type IncidentsProps = {
  incidents?: IncidentResource[];
};

export const Incidents: FC<IncidentsProps> = ({ incidents }) => {
  const columns = [
    {
      Header: "Numero",
      accessor: "numero",
      Cell: ({ row: { original } }: any) => (
        <CellResourceName
          title={original.numero}
          withLogo={false}
          viewLink={LINKS.incidents.view(original.id)}
        />
      ),
    },
    {
      Header: "Resume",
      accessor: "libelle",
      Cell: ({ row: { original } }: any) => (
        <Typography>{original.libelle}</Typography>
      ),
    },
    {
      Header: "Type",
      accessor: "typeIncident",
      Cell: ({ row: { original } }: any) => (
        <Typography>{original.typeIncident?.name}</Typography>
      ),
    },
    {
      Header: "Etat",
      accessor: "etatIncident",
      Cell: ({ row: { original } }: any) => (
        <Typography>{original.etatIncident?.name}</Typography>
      ),
    },
    {
      Header: "Auteur",
      accessor: "auteur",
      Cell: ({ row: { original } }: any) => (
        <Typography>{buildUserShortName(original.user)}</Typography>
      ),
    },
    {
      Header: "Date creation",
      accessor: "createdAt",
      Cell: ({ row: { original } }: any) => (
        <Typography>{original.createdAt}</Typography>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={incidents || []}
        emptyContent="Aucun incident trouvé"
      />
    </>
  );
};

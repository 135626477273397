import { AddCircle, ChangeCircle, ManageAccounts } from "@mui/icons-material";
import { Box } from "@mui/material";
import { MoreActions } from "components";
import { FC, useState } from "react";
import { EquipageResource, TourneeResource } from "types/transport.types";
import { AddDesserte } from "./AddDesserte";
import { ChangerEquipage } from "./ChangerEquipage";
import { ModifierEquipage } from "./ModifierEquipage";

type EquipageActionsProps = {
  tournee: TourneeResource;
  equipage: EquipageResource;
  updateEquipage: (data?: EquipageResource) => void;
  updateDessertes: () => void;
};

export const EquipageActions: FC<EquipageActionsProps> = ({
  tournee,
  equipage,
  updateEquipage,
  updateDessertes,
}) => {
  const [action, setAction] = useState<string | undefined>();

  const isActif = equipage.actif === "1";
  const isJonction = equipage.motifChangement?.id === "jonction";

  const closeModal = () => {
    setAction(undefined);
  };

  return (
    <>
      <Box sx={{ marginRight: 1 }}>
        <MoreActions
          onSelected={({ code }) => setAction(code)}
          actions={[
            {
              code: "modifier_equipage",
              subtitle: "Modifier les membres de l'équipage",
              title: "Modifier équipage",
              Icon: <ManageAccounts />,
              disabled: !isJonction && !isActif,
            },
            {
              code: "changer_equipage",
              title: "Changer équipage",
              subtitle: "Remplacer l'équipage par un autre",
              Icon: <ChangeCircle color="error" />,
              show: !isJonction,
              disabled: !isActif,
            },
            {
              code: "separator",
            },
            {
              code: "add_desserte",
              title: "Ajouter dessertes",
              subtitle: "Ajouter de nouvelles dessertes",
              Icon: <AddCircle color="info" />,
              disabled: !isActif,
              show: !isJonction,
            },
          ]}
        />
      </Box>

      {action === "modifier_equipage" && (
        <ModifierEquipage
          equipage={equipage}
          closeModal={closeModal}
          onFinished={(data) => {
            closeModal();
            updateEquipage(data);
          }}
        />
      )}
      {action === "changer_equipage" && (
        <ChangerEquipage
          equipage={equipage}
          closeModal={closeModal}
          onFinished={(data) => {
            closeModal();
            updateEquipage(data);
          }}
        />
      )}
      {action === "add_desserte" && (
        <AddDesserte
          equipage={equipage}
          tournee={tournee}
          closeModal={closeModal}
          onFinished={() => {
            closeModal();
            updateDessertes();
          }}
        />
      )}
    </>
  );
};

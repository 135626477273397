import { AddCircleRounded } from "@mui/icons-material";
import { Box } from "@mui/material";
import { MoreActions } from "components";
import { TrajetIcon } from "pages/common";
import { FC, useState } from "react";
import { EquipageResource } from "types/transport.types";
import { ModifierTrajet, AjouterTrajetSite } from "./GererTrajet";

type TrajetActionsProps = {
  equipage: EquipageResource;
  updateEquipage: (equipage: EquipageResource) => void;
};

export const TrajetActions: FC<TrajetActionsProps> = ({
  equipage,
  updateEquipage,
}) => {
  const [action, setAction] = useState<string | undefined>();

  const isActif = !!equipage.actif;

  const closeModal = () => {
    setAction(undefined);
  };

  return (
    <>
      <Box sx={{ marginRight: 1 }}>
        <MoreActions
          onSelected={({ code }) => setAction(code)}
          actions={[
            {
              code: "modifier_trajet",
              title: "Modifier trajet",
              subtitle: "Réordonner / Dupliquer les sites du trajet",
              Icon: <TrajetIcon />,
              disabled: !isActif,
            },

            {
              code: "add_site",
              title: "Ajouter sites",
              subtitle: "Ajouter de nouveaux sites au trajet",
              Icon: <AddCircleRounded color="info" />,
              disabled: !isActif,
            },
          ]}
        />
      </Box>

      {action === "modifier_trajet" && (
        <ModifierTrajet
          equipage={equipage}
          closeModal={closeModal}
          onFinished={(data) => {
            closeModal();
            updateEquipage(data);
          }}
        />
      )}

      {action === "add_site" && (
        <AjouterTrajetSite
          equipage={equipage}
          closeModal={closeModal}
          onFinished={(data) => {
            closeModal();
            updateEquipage(data);
          }}
        />
      )}
    </>
  );
};
